.general-with-image{
	&__content-wrap{
		@include make-row();
		padding-top: 7.7rem;
		padding-bottom: 7.7rem;
		margin: 0 auto;
		@include tablet(){
			padding: 0 $mobile-side-padding;
		}
	}
	&__img-wrap{
		@include make-col(6);
		@include tablet(){
			@include make-col(12);
			margin-bottom: 1rem;
		}
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}
	&__content{
		@include make-col(6);
		padding-top: 15.5rem;
		padding-bottom: 14.8rem;
		padding-#{$end-direction}: 0px;
		padding-#{$start-direction}: 25.6rem;

		@include tablet(){
			@include make-col(12);
			padding: 0;
		}
	}
	h1{
		@extend .page-title;
		@extend .page-title--small;
		margin-bottom: 6rem;
		@include tablet(){
			font-size: 2.6rem;
		}
	}
	@import "general-with-image/form";
	@import "general-with-image/text";
	@import "general-with-image/size-table";
}