.wishlist{
	&__content-wrap{
		padding:8.65rem 0 3.4rem;
		@include tablet(){
			padding:3.2rem $mobile_side_padding 2rem;
		}
	}
	&__title{
		text-align: center;
		margin-bottom: 5rem;
		@include tablet(){
			margin-bottom: 2.3rem;
			font-size: 2.4rem;
		}
	}
	&__products-wrap{
		@include make-row();
		margin:0 -1.75rem;
		@include tablet(){
			margin:0 -0.55rem;
		}
		.product{
			@include make-col(3);
			box-sizing: border-box;
			padding:0 1.75rem;
			margin-bottom: 5.9rem;
			@include tablet(){
				@include make-col(6);
				padding:0 0.55rem;
				margin-bottom: 2rem;
			}
		}
	}
}