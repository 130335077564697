.main-section{
    margin-bottom: 5rem;
    padding-bottom: 12rem;
    background-color: $light-bronze;
    @include tablet{
        margin-bottom: 2rem;
        padding-bottom: 0px;
        background-color: transparent;
    }
    &__content-wrap{
        @include make-row();
        margin: 0 auto;
        box-sizing: border-box;
        background-color: $white;
        padding: 6rem 12rem 5rem;
        max-width: 1161px;
        width: 60%;
        @include tablet{
            max-width: 100%;
            width: 100%;
            padding: 0 $mobile_side_padding;
        }
    }
    &__title-wrap{
        @include make-col(6);
        @include tablet{
            @include make-col(12);
        }
        h1{
            font-size: 32px;
            line-height: 29px;
            margin-bottom: 30px;
            font-weight: $semi-bold;
            @include tablet{
                font-size: 26px;
                margin-bottom: 10px;
            }
        }
        
    }
    &__share-wrap{
        @include make-col(6);
        text-align: $end-direction;
        a{
            margin-#{$start-direction}: 28px;
        }
        @include tablet{
            @include make-col(12);
            text-align: $start-direction;
            margin-bottom: 22px;;
            a{
                margin-#{$start-direction}: 0px;
                margin-#{$end-direction}: 28px;
            }
        }
    }
    &__text-wrap{
        @include make-col(12);
        font-size: 18px;
        line-height: 25px;
        @include tablet{
            font-size: 16px;
        }
        h3{
            font: inherit;
            font-weight: $bold;
            margin-bottom: 15px;
        }
        p{
            margin-bottom: 15px;
        }
        figcaption{
            margin: 19px 0 29px;
            font-size: 16px;
            color: #838383;
            @include tablet{
                margin: 11px 0 22px;
            }
        }
        ul{
            list-style-type: disc;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            padding-inline-start: 40px;
        }
    }
    p{
        font-size: 18px;
        line-height: 25px;
        @include tablet{
            font-size: 16px;
        }
    }
   
}