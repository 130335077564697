.btn, a, button{
    margin: 0;
    padding: 0;
    border: none;
	color: inherit;
    cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    text-decoration: none;
    letter-spacing: inherit;
    -webkit-appearance: none;
	background-color: transparent;
    transition: all .2s ease-in;
    &:focus{
    	outline: none;
    	box-shadow: none;
    }
    &:hover{
        color: inherit;
    }
    &--absolute{
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        #{$start-direction}: 0;
    }
    &--primary{
        color: inherit;
        border: 1px solid;
        font-size: 1.8rem;
        line-height: 4rem;
        display: inline-block;
        font-weight: $semi-bold;
        letter-spacing: .225rem;
        padding: 1.15rem 4.8rem;
        &:hover{
            color: $white;
            background: $primary;
        }
        @include tablet{
            padding: .4rem 4.8rem;
        }
        
    }
    &--primary-reverse{
        color: $white;
        background: $primary;
        &:hover{
            color: $primary;
            background: $white;
        }
    }
    &--secondary{
        background-color: $white;
        box-sizing: border-box;
        padding:1.4rem 8.65rem;
        text-transform: uppercase;
        font-weight: $semi-bold;
        line-height: 4rem;
        letter-spacing: 0.225rem;
        transition: all 0.2s ease-in-out;
        @include tablet(){
            letter-spacing: 0.12rem;
            padding:0.4rem 3.7rem;
        }
        &:hover{
            color:$white;
            background-color: $primary;
        }
    }
    &--cart{
        position: relative;
        &__items{
            display: none;
            position: absolute;
            bottom: 0.2rem;
            #{$end-direction}: 0;
            background-color: $text;
            border-radius:50%;
            color: $white;
            font-size: 1rem;
            width: 1.4rem;
            height: 1.4rem;
            display: block;
            text-align:center;
            @include tablet(){
                bottom: -0.5rem;
            }
        }
    }
    &--social{
        @include full_svg;
        display: inline-block;
        margin-#{$end-direction}:3.8rem;
        @include tablet{
            margin-#{$end-direction}: 1.2rem;
        }
        &:last-child(){
            margin:0;
        }
        &.footer__btn{
            width: 2.7rem;
            height: 2.7rem;
            margin-#{$end-direction}:1.2rem;
        }
    }
   
    &--search{
        z-index: 2;
        @include tablet{
            display: none !important;
        }
    }
    &--variations{
        display: inline-flex;
        &.active{
            padding: 1.5rem 2rem;
            font-weight: $semi-bold;
            background-color: $light-bronze;

        }
        &:disabled{
            position: relative;
            color: $gray;
            &::after{
                content:'';
                width: 90%;
                height: 1px;
                position: absolute;
                top:50%;
                #{$start-direction}: 5%;
                transform: rotate(-30deg);
                background-color: $gray;
                display: block;
            }
            &:hover{
                cursor:not-allowed;
            }
        }
    }
    &--color{
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0 0 0.8rem 0.8rem;
        box-sizing: content-box;
        // border: 0.4rem solid $gray;
        // &.active{
        //     box-shadow: 0 0 0 .1rem $gray;
        // }
        @include tablet(){
            width: 1.3rem;
            height: 1.3rem;
            margin:0 0 1.4rem 1.4rem;
        }
    }
    &--color-variation{
        margin: 0 0 0.8rem 0.8rem;
        border: 0.4rem solid $white;
        @include tablet(){
            border: 0.3rem solid $white;
            // margin: 0 0 0.8rem 1.3rem;
        }
        &.active{
            box-shadow: 0 0 0 .1rem $gray;
        }
    }
    &--filter{
        display: block;
        font-size: 1.6rem;

        line-height: 2.3rem;
        @include tablet{
            font-size: 1.4rem;
            line-height: 2.5;
        }
        &.active{
            text-decoration: underline;
        }
    }
    
    &--price-sort{
        @include tablet(){
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
    &--category{
        display: block;
        padding:0.8rem 1.1rem 1.2rem;
        font-weight: $normal;
        @include tablet(){
            font-size: 1.4rem;
            line-height: 2rem;
            padding: 0.5rem 1rem 0.7rem;
        }
        &.active{
            color:$cat-btn-color;
            background-color: $filters-bg-color;
        }
    }
    &--dropdown{
        display: flex;
        align-items: center;
        line-height: 4rem;
        @include tablet(){
            font-size: 1.4rem;
            line-height: 2rem;
        }
        &.active{
            svg{
                transform: rotate(180deg);
            }
        }
        svg{
            width: 0.7rem;
            height: 0.36rem;
            margin-#{$start-direction}: 0.5rem;
            transition: transform 0.2s ease-in-out;
            #Menu{
                stroke:$primary;
            }
        }
    }
    &--add-to-cart{
        color: $white;
        width: 100%;
        background-color: $primary;
        font-weight:$semi-bold;
        padding:1.1rem 2rem;
        &:hover, &:disabled{
            color: $primary;
            background-color: $white;;
        }
        @include tablet(){
            padding:1rem 2rem;
        }
    }
    &--quick-add-to-cart{
        padding:0rem 1.35rem;
        font-size: 1.2rem;
        line-height: 2.9rem;
        letter-spacing: normal;
    }
    &--quick-add-ind{
        width: 4.8rem;
        height: 4.8rem;
        background:url(images/quick_add_plus.svg) center / 99% no-repeat;
        @include tablet(){
            width: 2.4rem;
            height: 2.4rem;
        }
        &.active{
            background:url(images/quick_add_check.svg) center / 99% no-repeat;
        }
    }
    &--like{
        z-index: 1;
        span{
            font-size: 1.5rem;
            display: inline-block;
            margin-#{$start-direction}:1rem;
            @include tablet{
                display: none;
            }
        }
        &.active{
            span{
                display: none;
            }
            .icon--wishlist{
                svg{
                    #DT, #HP{
                        fill: $primary;
                    }
                }
            }
        }
    }
   &--social-login{
        height: 4.9rem;
        width: 11.3rem;
        border: 1px solid $primary;
        .icon{
            width: 100%;
            height: 100%;
        }
    }
    &--social-login-facebook{
        &__icon{
            
            background: url('images/facebook-social-login.svg') center / 0.8rem 2rem no-repeat;
        }
    }
    &--social-login-google{
        &__icon{
            background: url('images/google-social-login.png') center / 2rem 2rem no-repeat;
        }
    }
    
    &--sale-menu{
        opacity: 0;
        background: rgba(246,243,240,0.5) url(images/btn-arrow.svg) center / 2.9rem  no-repeat;
    }
}
