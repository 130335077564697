.related{
	margin: 20.1rem 0 13rem;
	@include tablet(){
		margin: 4rem 0 3.2rem;
	}
	&__title{
		margin-bottom: 7rem;
		@include tablet(){
			margin-bottom: 3.2rem;
		}
	}
	.posts-preview-wrap{
		@include tablet(){
			padding: 0 2rem;
		}
	}
}