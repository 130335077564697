.post-navigation{
	padding: 2rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include tablet(){
		padding:4rem 0;
	}
	&__link{
		&--next{
			margin-#{$end-direction}: auto;
		}
	}
}