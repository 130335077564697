.woocommerce-checkout{
	background-color: $checkout-bg;
	&__titles-wrap{
		text-align: center;
		margin-bottom: 5.5rem;
		@include tablet(){
			margin-bottom: 3.6rem;
		}
	}
	&__title{
		@include tablet(){
			font-size: 2.4rem;
		}
	}
	&__subtitle{
		margin-top: 0.5rem;
		font-size: 2rem;
		font-weight: $normal;
		@include tablet(){
			font-size: 1.6rem;
		}
	}
	&__main-content{
		max-width: 116rem;
		margin:0 auto;
		.woocommerce{
			padding: 8.7rem 0 10rem;
			@include tablet(){
				padding:3.6rem 0 3.9rem;
			}
			.woocommerce-error{
				line-height: 1.2;
				@include tablet(){
					padding: $mobile-side-padding;
					margin-bottom: 1.5rem;
				}
			}
			.woocommerce-form-coupon-toggle{
				display: none;
			}
			.checkout_coupon{
				// position: relative;
    			// #{$end-direction}: 0;
				// bottom: calc( -6rem + var(--simply-club-var));
    			// bottom: -24rem;
				
    			// width: 38.27586%;
				margin-bottom: 3rem;
    			margin-#{$start-direction}: auto;
				display: flex !important;
				// border-bottom:1px solid $primary;
				box-sizing: border-box;
				padding:1rem 1.8rem;
				background-color: $white;
				@include tablet(){
					bottom: 0;
					width: 100%;
				}
				p{
					&:not(.form-row){
						display: none;
					}
					&.form-row-first{
						width: 100%;
						input{
							width: 100%;
							padding:0;
							border:0;
							height: 100%;

						}
					}
					&.form-row-last{
						button{
							@extend .btn;
							background-image: url(images/coupon_btn.svg);
							background-size: 99%;
							background-repeat: no-repeat;
							background-position: center;
							border-radius: 50%;
							height: 2.8rem;
							width: 2.8rem;
						}
					}
				}
			}
			
			h3{
				font-size: 2.4rem;
				line-height: 3.2rem;
				font-weight: $semi-bold;
				@include tablet(){
					font-size: 1.8rem;
					line-height: 2.3rem;
				}
			}
		}
		.woocommerce-checkout{
			@include make-row();
			@include tablet(){

			}
			.sc-card{
				order: 1;
				@include make-col(12);
				width: 100% !important;
				margin-bottom: 4rem;
				border: none;
				border-radius: 0px;
				padding: 2.9rem 4rem 3.5rem;
				p{
					line-height: 1.2;

				}
				table{
					th{
						text-align: $start-direction;
					}
				}
				@include tablet{
					padding: 1rem 2rem;
				}
			}
			#sc_discount input.reliasationis{
				width: auto !important;
			}
			#customer_details{
				order: 2;
				@include make-col(682, 1160);
				@include make-col-gap(34, 1160);
				background-color: $white;
				box-sizing: border-box;
				padding:2.9rem 6rem 0;
				@include tablet(){
					@include make-col(12);
					@include make-col-gap(0);
					padding:3.6rem $mobile_side_padding 0;
				}
				.col-1{
					position: relative;
					margin-bottom: 0.8rem;
					
				}
				.col-2{
		
				}
			}
			#order_review{
				order: 3;
				@include make-col(444, 1160);
				background-color: $white;
				box-sizing: border-box;
				padding: 2.9rem 4rem 3.5rem;
				@include tablet(){
					@include make-col(12);
					margin: 3rem auto 0;
					padding:2.8rem 2.4rem 2.5rem;
				}
				.order_review_heading{
					margin-bottom: 3.8rem;
					@include tablet(){
						font-weight: $bold;
						margin-bottom: 1.9rem;
					}
				}
				.woocommerce-checkout-review-order-table{
					text-align: $start-direction;
					border-collapse: collapse;
					line-height: 1.2;

					td, th{
						padding: 1rem 0;
						&.product-name{
							width: 28%;
							text-align: $start-direction;
						}
					}
					td{
						text-align: $end-direction;
					}
					th{
						&.product-total{
							text-align: $end-direction;
						}
					}
					.woocommerce-shipping-totals{
						vertical-align: initial;
						td{
							text-align: $start-direction;
						}
						.woocommerce-shipping-methods{
							li{
								line-height: 1.2;
								margin:1.5rem 0;
								label{
									font:inherit;
									position: relative;
									margin-#{$start-direction}: 1rem;
									display: block;
									&:hover{
										cursor: pointer;
									}
									&::before{
										visibility: visible;
										-webkit-transition: all 0.3s ease-in;
									    -o-transition: all 0.3s ease-in;
									    transition: all 0.3s ease-in;
									    content: '';
									    border-radius: 50%;
									    position: absolute;
									    #{$start-direction}: -3rem;
									    width: 1.5rem;
									    height: 1.4rem;
									    border: 0.25rem solid $white;
									    top: 0.2rem;
									    background-color: $white;
									    box-shadow: 0 0 0 1px ;
									    @include tablet{
									    	top: .3rem;
									    }
									}
								}
								.shipping_method{
									opacity: 0;
									margin-#{$end-direction}: 2rem;
									&:hover{
										cursor: pointer;
									}
									// border:0;
									// border: 1px solid #979797;
									// padding: 1.5rem 0;
									// border-radius: 0px;
									// -webkit-appearance:none;
									// background: $white url(images/arrowdownwards_black.svg) 2.3rem 2.2rem/1.1rem 0.8rem no-repeat;
									// width: 100%;
									// padding-right: 1.6rem;
									// &:focus{
									// 	outline: none;
									// }
									&:checked ~ label::before{
										background-color: $primary;
									}
								}
							}
						}

					}
					.order-total{
						td, th{
							border-top: 1px solid $gray;
							font-size: 2.4rem;
							@include tablet(){
								padding:1.9rem 0 ;
							}
						}
						th{
							@include tablet(){
								font-size: 1.8rem;
								font-weight: $normal;
							}
						}
					}
				}
				#payment{
					margin-top: 2rem;
					ul.payment_methods{
						padding: 0;
						border-bottom: 0;
						.wc_payment_method{
							position: relative;
							margin-bottom: 1.5rem;
							label{
								
								font-size: 1.8rem;
								color: $primary;
								padding-#{$start-direction}: 2.5rem;
								margin-bottom: 0.5rem;
								display: inline-block;
								&:hover{
									cursor: pointer;
								}
								&:before{
									visibility: visible;
									-webkit-transition: all 0.3s ease-in;
								    -o-transition: all 0.3s ease-in;
								    transition: all 0.3s ease-in;
								    content: '';
								    border-radius: 50%;
								    position: absolute;
								    #{$start-direction}: 0;
								    width: 1.5rem;
								    height: 1.4rem;
								    border: 0.25rem solid $white;
								    top: 0.3rem;
								    background-color: $white;
								    box-shadow: 0 0 0 1px $primary;
								}
							}
							.payment_box{
								
							}
							
							.input-radio{
								visibility: hidden;
								margin-#{$end-direction}: 1.6rem;
								position: absolute;
								&:hover{
									cursor: pointer;
								}
								
								&:checked ~ label::before{
									
										background-color: $primary;
									
								}
							}
						}
					}
					#place_order{
						margin-top: 3rem;
						@extend .btn;
						@extend .btn--primary;
						@extend .btn--add-to-cart;
						width: 100%;
					}
				}
			}
			#order_review_heading{
				display: none;
			}
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover, 
		input:-webkit-autofill:focus, 
		input:-webkit-autofill:active  {
		    box-shadow: 0 0 0 30px $white inset !important;
		    color: $text !important;
		    -webkit-text-fill-color: $text !important;
		}
	}
}

.woocommerce-form__label-for-checkbox{
	position: relative;
	font:inherit !important;
	padding-#{$start-direction}: 2.5rem;
	display: inline-block;

	&::before{
		visibility: visible;
		-webkit-transition: all 0.3s ease-in;
		-o-transition: all 0.3s ease-in;
		transition: all 0.3s ease-in;
		content: '';
		border-radius: 50%;
		position: absolute;
		#{$start-direction}: 0;
		width: 1.5rem;
		height: 1.4rem;
		border: 0.25rem solid $white;
		top: 0.3rem;
		background-color: $white;
		box-shadow: 0 0 0 1px $primary;
		vertical-align: middle;
	}
	&.active{
		&::before{
			background-color: $primary;
		}
	}
	input{
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: 0;
		cursor: pointer;
		visibility: hidden;
		position: absolute;
		#{$start-direction}: 0px;
	}
}

.joinClubMember, .registerClubMember{
	&-inner{
		box-sizing: border-box;
	}

	background-color: rgba(33,33,33,0.7);
	h4{
		margin-bottom: 2rem !important;
	}
	input{
		border: 1px solid;
	}
	button{
		margin: 0px;
		@extend .btn--primary;
		@extend .btn--primary-reverse;
		margin-bottom: 2rem;
		@include tablet{
			margin-top: 2rem;
		}
	}
	span.clubmemberClose{
		font-size: 0px;
		top: 2.6rem;
		width: 2.4rem;
		height: 2.4rem;
		#{$end-direction} : 2.6rem;
		#{$start-direction}: unset;
		background: url(images/close.svg) center / contain no-repeat;
	}
}
.clubBtnSty{
    padding: 1rem 2rem 0.7rem;
    margin-bottom: 2rem;
}