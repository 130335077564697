.badge, .onsale{
	position: absolute;
	font-size: 1.4rem;
	font-weight: $semi-bold;
	box-sizing: border-box;
	line-height: 2rem;
	z-index: 3;
	#{$start-direction}:0;
	&--index{
		padding:0.3rem 1.3rem;
	    top: 1.2rem;
	    @include tablet(){
	    	top: 1rem;
	    	padding: 0.2rem 1rem;
	    	font-size: 1rem;
			line-height: 1;
	    }
	}
	&--single{
		padding:0.8rem 2rem;
		top: 2.2rem;
		@include tablet(){
			font-size: 1.4rem;
			top: 1.6rem;
			padding: 0.3rem 1.3rem;
		}
	}
	&--out-of-stock{
		background-color: #F6F3F0;
	}
}

.onsale{
	top: 1.2rem;
	padding: 	0.3rem 1.3rem;
	background-color: $primary;
	color: $white;
}
