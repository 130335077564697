.footer{
	font-size: 1.8rem;
	line-height: 4rem;
	font-weight: $light;
	color: $white;
	background: #2E2E2E;
	.footer-bottom{
		padding: 0;
		color: $primary;
		max-width: 100%;
		font-size: 1.5rem;
		background: $light-bronze;
		@include tablet{
			font-size: 1.4rem;
			line-height: 1;
		}
		
		&__content-wrap{
			display: flex;
			align-items: center;;
			justify-content: space-between;
			@include tablet{
				padding-top: 1rem;
				padding-bottom: 1.1rem;;
			}
			
			img{
				height: 1.35rem;
				width: 1.05rem;
				object-fit: contain;
				margin-#{$end-direction}: .7rem;
				@include tablet{
					vertical-align: middle;
					padding-bottom: .3rem;
				}
				
			}
		}
		&__segment{
			@include tablet{
				&:last-child{
					display: none;;
				}
			}
		}
		
	}
	&__content-wrap{
		&:not(.footer-bottom){
			@include make-row();
			margin: 0 auto;
			padding-top: 4.8rem;
			padding-bottom: 4.1rem;
			@include tablet{
				padding-top: 3.4rem;
				padding-bottom: 4.8rem;
			}
		}
	}
	h3{
		font-size: inherit;
		font-weight: $bold;
		line-height: inherit
	}
	&__segment{
		@include make-col(2);
		@include tablet{
			@include make-col(12);
		}
		&:nth-child(1){
			@include tablet{
				display: none;
			}
		}
		&.form-wrap{
			@include make-col(5);
			
			padding-#{$start-direction}: 13.7rem;
			padding-#{$end-direction}: 15.6rem;
			@include tablet{
				@include make-col(12);
				padding: 0;
				margin-bottom: 2.6rem;
				
			}
		}
		&:nth-child(2){
			@include tablet{
				display: none;;
			}
			strong, h3{
				font-weight: $semi-bold;
			}
			p{
				white-space: nowrap;
			}
			a{
				text-decoration: underline;
			}
		}
		&:nth-child(4){
			@include tablet{
				order: 5;
				text-align: center;
			}
		}
		&.phone-wrap{
			line-height: 3rem;
			@include make-col(1);
			@include tablet{
				@include make-col(12);
				order: 4;
				margin-bottom: 1.4rem;
			}
			.text-wrap{
				@include tablet{
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			img{
				padding: 2.4rem;
				width: 6.9rem;
				height: 6.9rem;
				display: block;
				border: 1px solid;
				border-radius: 50%;
				object-fit: contain;
				margin-bottom: 1.5rem;
				box-sizing: border-box;

				@include tablet{
					width: 4.8rem;
					height: 4.8rem;
					padding: 1.6rem;
					margin-bottom: 0;
					display: inline-block;
					margin-#{$end-direction}: 1.2rem;
				}
			}
		}
	}
}