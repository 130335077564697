&--addtional-scent{
    @include tablet{
        @include make-col(6);
        padding: 0 1.1rem;
        margin-bottom: 2rem;
    }
    &__content-wrap{
        position: relative;
    }
    &__image-wrap{
        @include full-svg;
        width: 20.4rem;
        height: 20.4rem;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto 2.8rem;
        background-color: $white;
        @include tablet{
            width: 14.3rem;
            height: 14.3rem;
            margin-bottom: 1.2rem;
            
        }
    }
    &__text-wrap{
        padding: 0 1rem;
        @include tablet{
            padding: 0;
        }
        h3{
            font-size: 1.8rem;
            letter-spacing: 0;
            text-align: center;
            line-height: 2.9rem;
            font-weight: $semi-bold;
            @include tablet{
                font-size: 1.6rem;
            }

        }
    }
}