.title-banner{
    &__content-wrap{
        margin-top: 7.8rem;
        position: relative;
        @include tablet{
            margin-top: 0px;
        }
    }
    &__image-wrap{
        img{
            width: 100%;
            height: auto;
        }
    }
    &__title-wrap{
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        #{$start-direction}: 0px;
        h1{
            color: $white;
            font-size: 8.8rem;
            text-align: center;
            font-weight: $semi-bold;
            @include tablet{
                font-size: 2.6rem;
            }
        }
    }
}