.variations{
	&__label{
		font-size: 1.6rem;
		@include tablet(){
			font-size: 1.2rem;
			line-height: 1;
		}
	}
	&__container{
		&--index{
			margin-top: 0.9rem;
			@include tablet(){
				margin-top: 1.2rem;
			}
		}
	}
	&__link{
		font-size: 1.4rem;
		text-decoration: underline;
		display: inline-block;
		margin-top: 1.3rem;
		@include tablet(){
			
		}
	}
}