.dropdown{
	position: relative;
	&__content-wrap{
		position: absolute;
		top: 100%;
		height: 0;
		overflow: hidden;
		background-color: $dropdown-bg-color;
		z-index: 5;
	}
	&__content{
		box-sizing: border-box;
		padding:2rem 1.9rem 0.9rem;
	}
}