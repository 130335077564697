.sale-points{
    .title-banner{
        margin-bottom: 3.8rem;
        @include tablet{
            margin-bottom: 1.4rem;
        }
    }
    .flag-store{
        margin-bottom: 6.2rem;
        @include tablet{
            margin-bottom: 4.1rem;
        }
        &__text-wrap{
            text-align: center;
            img{
                width: 7.3rem;
                display: block;
                height: 9.6rem;
                object-fit: contain;
                margin: 0 auto 3rem;
                @include tablet{
                    width: 5.1rem;
                    height: 6.7rem;
                    margin-bottom: 4.1rem;
                }
            }
            h2{
                font-size: 3.2rem;
                line-height: 2.9rem;
                margin-bottom: 1rem;
                @include tablet{
                    font-size: 2.6rem;
                    margin-bottom: 1.4rem;
                }
            }
            p{
                @include tablet{
                    font-size: 1.8rem;
                    line-height: 2.6rem;
                    letter-spacing: -.008rem;
                }
            }
           
        }
    }
    &__container{
        margin-bottom: 9rem;
    }
}