.thank-you{
	&__main-content{
		max-width: 116rem;
		margin:0 auto;
		.woocommerce{
			padding: 8.7rem 0 10rem;
			@include tablet(){
				padding:3.6rem $mobile-side-padding 3.9rem;
			}
			.woocommerce-thankyou-order-received{
				@extend .page-title;
				@extend .page-title--small;
			}
			.woocommerce-thankyou-order-details{
				margin-top: 2rem;
			}
			.woocommerce-order-details{
				text-align: $start-direction;
				margin-top: 2rem;
				th,td{
					padding: 0.5rem 0 0.5rem 3rem;
				}
			}
		}
	}
}