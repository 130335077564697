.account{
	&__content-wrap{
		padding: 7rem 0 12rem;
		max-width: 92.2rem;
		margin:0 auto;
		@include tablet(){
			padding:3.2rem $mobile_side_padding 2rem;
			max-width: 100%;
		}
	}
	&__title{
		text-align: center;
		margin-bottom: 6rem;
		@include tablet(){
			font-size: 2.4rem;
			margin-bottom: 2.3rem;
		}
	}
	.woocommerce{
		.woocommerce-MyAccount-navigation{
			@include tablet(){
				margin-bottom: 3rem;
			}
			ul{
				display: flex;
				@include tablet(){
					display: block;
				}
				li{
					margin-#{$end-direction}: 4rem;
					@include tablet(){
						margin-#{$end-direction}:0;
						margin-bottom: 1rem;
					}
					a{
						padding-bottom: 2rem;
						font-weight: $semi-bold;
						display: inline-block;
						@include tablet(){
							padding-bottom: 0;
						}
					}
					&:last-child(){
						margin:0 auto 0 0;
						a{
							display: inline-flex;
							align-items: center;
							font-size: 1.4rem;
							color: $bronze;
							&::before{
								content:'';
								background-image: url(images/logout_icon.svg);
								display: block;
								width: 1.5rem;
								height: 1.6rem;
								background-size: 99%;
								background-repeat: no-repeat;
								background-position: center;
								margin-#{$end-direction}: 0.8rem;
							}
						}
					}
					&.is-active{
						a{
							border-bottom: 1px solid;
						}
					}
				}
			}
		}
		.woocommerce-MyAccount-content{
			background-color: $account-bg-color;
			padding:5rem;
			@include tablet(){
				padding: 2rem;
			}
			h3{
				font-size: 1.4rem;
				margin-bottom: 3.5rem;
			}
			button{
				@extend .btn;
				@extend .btn--primary;
				display: block;
				margin:0 auto;
				box-sizing: border-box;
				font-weight: $semi-bold;
				padding:0.5rem 7.5rem;
				margin-top: 4.8rem;
				@include tablet(){
					margin-top: 2rem;
					width: 100%;
				}
			}
			form{
				padding:3rem 6.5rem 4.6rem;
				@include tablet(){
					padding:1rem 0 0 0;
				}
			}
			a{
				text-decoration: underline;
			}
			.woocommerce-Address{
				margin-top: 2.5rem;
				h3{
					margin-bottom: 0.3rem;
				}
				a{
					font-size: 1.4rem;
					text-decoration: none;
					color: $bronze;
					display: inline-flex;
					align-items: center;
					&::before{
						content:'';
						background-image: url(images/edit_icon.svg);
						display: block;
						width: 1.4rem;
						height: 1.4rem;
						background-size: 99%;
						background-repeat: no-repeat;
						background-position: center;
						margin-#{$end-direction}: 0.8rem;
					}
				}
				address{
					font-style: normal;
					margin-top: 0.5rem;
				}
			}
			.account-orders-table{
				width: 100%;
				text-align: $start-direction;
				@include tablet(){
					font-size: 1.2rem;
					text-align: center;
				}
				thead{
					th{
						padding-bottom: 1.9rem;
					}
				}
				tbody{
					td{
						padding-bottom: 2.5rem;
					}
				}
				a{
					color: $bronze;
				}
			}
		}
	}
}