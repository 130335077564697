.filters{
	order:2;
	@include make-col(1.5);
	@include make-col-gap(1.5);
	@include tablet(){
		display: block;
		order: 1;
		@include make-col(12);
		@include make-col-gap(0);
	}
	&__categories-links-wrap{
		display: flex;
		overflow: auto;
		.btn--category{
			margin-left: 0.6rem;
			&:last-child(){
				margin-left:0;
			}
		}
	}
	.filters-container{
		@include tablet{
			display: flex;
		}
		.filter{
			margin-bottom: 1rem;
			padding-bottom: 2rem;
			border-bottom: 1px solid #D6D5D5;
			

			h2{
				
				font-size: 1.8rem;
				font-weight: $bold;
				line-height: 4rem;
				text-align: $start-direction;
			}
			
			
		}
	}
}