
@font-face {
	font-family: 'RAGSans';
	src: url('fonts/RAG-Sans-1.1-Regular.eot');
	src: url('fonts/RAG-Sans-1.1-Regular.woff') format('woff'),
		 url('fonts/RAG-Sans-1.1-Regular.ttf')  format('truetype');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'RAGSans';
	src: url('fonts/RAG-Sans-1.1-Medium.eot');
	src: url('fonts/RAG-Sans-1.1-Medium.woff') format('woff'),
		 url('fonts/RAG-Sans-1.1-Medium.ttf')  format('truetype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'RAGSans';
	src: url('fonts/RAG-Sans-1.2-SemiBold.woff') format('woff'),
		 url('fonts/RAG-Sans-1.2-SemiBold.woff2')  format('woff2');
	font-style: normal;
	font-weight: 600;
}

@font-face {
	font-family: 'RAGSans';
	src: url('fonts/RAG-Sans-1.1-Bold.eot');
	src: url('fonts/RAG-Sans-1.1-Bold.woff') format('woff'),
		 url('fonts/RAG-Sans-1.1-Bold.ttf')  format('truetype');
	font-style: normal;
	font-weight: 700;
}

//see changes for font family
$light:400;
$normal:400;
$medium:500;
$semi-bold:600;
$bold:700;
html {
	font-size: 10px;
	@include desktop{
		font-size: 0.521vw;
	}
  	@include tablet{
  		font-size: 2.778vw;
  	}
  	@include mobile{
    	font-size: 2.778vw;
  	}
}
body {
	font-size: 1.9rem;
	// font-size: 22px;
	color: $text;
	font-family: $font;
	line-height: normal;
  	letter-spacing: normal;
  	font-weight: $normal;
	line-height: 1.2;
	@include tablet{
		font-size: 1.6rem;
		line-height: 2.3rem;	
	}
}
h1,h2,h3,h4,h5,h6,p{
	margin: 0 ;
	color: inherit;
	line-height: inherit;
}
.page-title{
	font-weight: $normal;
	font-family: $font;
	font-size: 8.8rem;
	line-height: 1;
	letter-spacing: -0.2rem;
	@include tablet(){
		font-size: 4rem; 
		line-height: 3.4rem;
		letter-spacing: normal;
	}
	&--white{
		color: $white;
	}
	&--small{
		font-size: 4rem;
		letter-spacing: normal;
		font-weight: $semi-bold;
		@include tablet(){
			font-size: 2rem;
		}
	}
	&--smaller{
		font-weight: $normal;
		font-size: 2.4rem;
		letter-spacing: normal;
		@include tablet(){
			font-size: 1.8rem;
		}
	}
}

h1{
	@extend .page-title;
}

.section-title{
	font-weight: $semi-bold;
	font-size: 3.5rem;
	letter-spacing: 0;
	text-align: center;
	line-height: 8.14rem;
	@include tablet{
		line-height: 1;
		font-size: 2rem;
	}
}
h2{
	@extend .section-title;
}

p{
	margin: 0;
}

