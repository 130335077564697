.text{
	&__wrap{
		max-width: 44.5rem;
		.lower-box{
			margin-top: 6rem;
			border:1px solid $gray;
			box-sizing: border-box;
			padding:2.4rem 1.6rem 2.4rem 2.4rem;
			line-height: 3.8rem;
			@include tablet(){
				margin-top: 3.2rem;
			}
			&__title{
				font-weight: $semi-bold;
				margin-bottom: 0.6rem;
				padding-#{$start-direction}: 0.8rem;
			}
			&__link{
				display: inline-flex;
				align-items: center;
				margin-bottom: 0.3rem;
				span{
					margin-#{$start-direction}: 1.6rem;
				}
			}
			&__icon-wrap{
				width: 4.3rem;
				text-align: center;
			}
		}
	}
}