.banner{
    padding-bottom: 8.8rem;
    background-color: $light-bronze;
    @include tablet{
        padding-bottom: 0px;
        background-color: transparent;
        margin-bottom: 1.8rem;
    }
    &__content-wrap{
        padding-top: 7.8rem;
        @include tablet{
            padding-top: 0px;
        }
    }
    &__image-wrap{
        img{
            width: 100%;
            height: auto;
        }
    }
}