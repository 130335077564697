.additional-scents{
    margin-bottom: 19.2rem;
    @include tablet{
        margin-bottom: 4.7rem;
    }
    h2{
        margin-bottom: 3.9rem;
    }
    .slider--products{
        @include tablet{
            @include make-row();
            &__slide{
                @include make-col(6);
            }
        }
    }
}