// input , textarea{
// 	display: block;
// 	margin: 0;
// 	padding: 0;
// 	z-index: 1;
// 	width: 100%;
// 	border: 0px;
// 	color: inherit;
// 	font: inherit;
// 	resize: none;
// 	position: relative;
// 	border-radius: 0px;
// 	font-weight: normal;
// 	box-sizing: border-box;
// 	-webkit-appearance: none;
// 	background-color: transparent;
	
// 	&:focus{
// 		outline: none;
// 		box-shadow: none;
// 	}
// }

form{
	direction: $direction;
}
.wpcf7-spinner{
	display: none;
}
div.wpcf7{
	input , textarea{
		display: block;
		margin: 0;
		padding: 0;
		z-index: 1;
		width: 100%;
		border: 0px;
		color: inherit;
		font: inherit;
		resize: none;
		position: relative;
		border-radius: 0px;
		font-weight: normal;
		box-sizing: border-box;
		-webkit-appearance: none;
		background-color: transparent;
		
		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
	.wpcf7-form{
		.ajax-loader{
			display: none;
		}
		br{
			display: none
		}
		.form-wrap{
			.wpcf7-form-control-wrap{
				display: block;
				width: 100%;
				box-sizing: border-box;
				@include tablet(){
				}
				.wpcf7-not-valid-tip{
					display: none !important;
				}
				input{
					height: 100%;
					color: $text;
					
					direction: inherit;
					padding: 1rem 2rem 1.2rem;
					@include tablet(){
						
					}
					&.wpcf7-not-valid{
						background-color:rgba(255, 64, 64, 0.5);
					}
				}
				textarea{
					max-height: 14.5rem;
		
					padding: 1rem 2rem 1.2rem;
				}
				
				input:-webkit-autofill,
				input:-webkit-autofill:hover, 
				input:-webkit-autofill:focus, 
				input:-webkit-autofill:active  {
				    box-shadow: 0 0 0 30px $white inset !important;
				    color: $text !important;
				    -webkit-text-fill-color: $text !important;
				}
			}
			.submit-wrap{
				text-align: $start-direction;
				position: relative; 
				.wpcf7-submit{
					cursor: pointer;
					color: $white;
					font-weight: $semi-bold;
				    box-sizing: border-box;
					background-color: $bronze;
				    padding: 1rem 3.7rem 1.2rem;
				   
				    @include tablet(){
				    	padding:1.5rem 2.6rem;
				    	letter-spacing: 0.1rem;
				    }
				}
			}
			&--footer{
				width: 100%;
				display: flex;
				align-items: center;
				margin:0 auto;
				font-size: 1.6rem;
				line-height: 1.3rem;;
				@include tablet(){
					width: 100%;
				}
				
				.wpcf7-form-control-wrap{
					width: auto;
					input{
						background-color: $white;
						height: 5.2rem;
						padding: 2rem;
						@include tablet(){
							height: 4rem;
							font-size: 1.4rem;
						}
					}
				}
				.submit-wrap{
					.wpcf7-submit{
						height: 5.2rem;
						padding: 1.9rem 3.4rem; 
						@include tablet(){
							height: 4rem;
							padding-top:0;
							padding-bottom: 0;
						}
					}
				}
			}
			&--sitonait{
				margin-top: 3.6rem;
				@include make-row();
				justify-content: space-between;
				.form-wrap__form-input-wrap{
					@include make-col(12);
					&--half{
						@include make-col(5.5);
						@include tablet{
							@include make-col(12);
						}
					}
				}
				.wpcf7-form-control-wrap{
					border:1px solid $primary;
					margin-bottom: 3rem;
					input{
						height: 4.8rem;
					}
				}
				.submit-wrap{
					.wpcf7-submit{
						width: 100%;
						height: 4.8rem;
						background-color: $primary;
						@include tablet(){
							font-size: 1.8rem;
							padding:0;
						}
					}
				}
			}
			&--contact{
				margin-top: 4rem;
				@include tablet{
					margin-top: 1rem;
				}
				.wpcf7-form-control-wrap{
					border:1px solid $primary;
					margin-bottom: 3rem;
					input{
						height: 4.8rem;
					}
				}
				.submit-wrap{
					.wpcf7-submit{
						width: 100%;
						height: 4.8rem;
						background-color: $primary;
						@include tablet(){
							font-size: 1.8rem;
							padding:0;
						}
					}
				}
			}
		}
		.wpcf7-response-output{
			color: $black;
			text-align: center;
			padding:0;
			margin:0;
			border: 0;
			margin-top: 2rem;
			@include tablet(){
			}
		}
	}
}

.form{
	&__input-wrap{
		text-align: $start-direction;
		input{
			height: 6rem;
			direction: $direction;
			color: $primary;
			line-height: 2.9rem;
			background-color: $white;
			padding: 1.89rem 2.45rem 1.7rem;
			@include tablet{
				padding: 1.65rem 1.4rem 1.7rem;
			}
			&[type="email"] , &[type="tel"], &[type="url"]  {
				direction: $direction;
			}
			
			
		}
		&--submit{
			
			input{
				cursor: pointer;
				direction: $opposite-direction;
				font-weight: bold;
				text-align: center;
				background-color: $white;
				padding: 1.2rem 2.7rem 1.2rem;
				&:hover{
					color: $white;
					background-color: $primary;
				}
				@include tablet{
					padding: 1.2rem 1.6rem 1.2rem;
				}
			}
		}
	}
	&__msg{
		&--error{
			font-size: 1.6rem;
			color: $invalid;
			line-height: 2.3rem;
		}
	}
	&--tranquilo-login{
		input{
			margin: 0;
			padding: 0;
			z-index: 1;
			width: 100%;
			border: 0px;
			font: inherit;
			resize: none;
			font: inherit;
			color: inherit;
			display: block;
			position: relative;
			text-align: center;
			border-radius: 0px;
			font-weight: inherit;
			text-rendering: auto;
			box-sizing: border-box;
			-webkit-appearance: none;
			background-color: transparent;		
			&:focus{
				outline: none;
				box-shadow: none;
			}
		}
		&__input-wrap:not(.form--tranquilo-login_input-wrap--submit){
			margin-bottom: 4rem;
			@include tablet{
				margin-bottom: 2rem;
			}
			input{
				border: 1px solid $primary;
				padding: 1.3rem 1.7rem 1.2rem;
			}
		}
		&__input-wrap{
			
			input{
				padding: 1.3rem 1.7rem 1.2rem;
			}	
		}
		&__msg{
			margin-bottom: 4rem;
			@include tablet{
				margin-bottom: 2rem;
			}
			p{
				margin-bottom: 0.5rem;;
			}
		}
	}
}
.woocommerce-checkout .form-row {
	padding: 0px;
	margin: 0;
	label{
		font-size: 1.6rem;
		line-height: inherit;
		font-weight: inherit;
	}
	input{
		line-height: 1.2 !important;
	}
}
.sc-registration-field {

	margin-bottom: 2rem;
	
}
 .woocommerce-billing-fields, .woocommerce-shipping-fields,  .woocommerce-address-fields, .woocommerce-additional-fields, .edit-account, .woocommerce-ResetPassword{
	label{
		position: absolute;
		#{$start-direction}: 1.5rem;
		top: 1.3rem;
		font-size: 1.6rem;
		transition:all 0.2s ease-in-out;
		line-height: inherit;
    	font-weight: inherit;
		&.up{
			transform: translate(1.5rem, -3rem);
			font-size: 1rem;
			@include tablet(){
				transform: translate(1.5rem, -3.5rem);
			}
		}
		.required{
			text-decoration: none;
		}
		.optional{
			display: none;
		}	
	}
	
	.form-row, .form-group{
		@include make-col(12);
		position: relative;
		margin-bottom: 3.2rem;
		border:1px solid $primary;
		// &.place-order{
		// 	padding: 0px;
		// }
		// &.validate-required{
		// 	border: 0px;
		// }
		@include tablet(){
			margin-bottom: 2.8rem;
		}
		&--quarter{
			@include make-col(112, 562);
			@include tablet(){
				@include make-col(262, 562);
			}
		}
		&--half{
			@include make-col(262, 562);
			@include tablet(){
				@include make-col(12);
			}
		}
		&--full{
			@include make-col(12);
		}
		
		input, textarea{
			width: 100%;
			background:transparent;
			color: inherit;
			font-family: inherit;
			font-size: 1.6rem;
			line-height: 1.2;
			letter-spacing: normal;
			border:0;
			box-sizing: border-box;
			padding:1.1rem 1.5rem;

		}
		textarea{
			resize: none;
			height: 13rem;
			border:1px solid $primary;
		}
		em{
			display: none;
		}
		button{
			@extend .btn;
			@extend .btn--primary;
		}
	}
	fieldset{
		border:0;	
		padding: 0;
		margin:0;
		legend{
			padding:0;
			font-size: 1.4rem;
			font-weight: $bold;
			margin-bottom: 2.5rem;
		}
	}
	h3{
		margin-bottom: 3.8rem;
		@include tablet(){
			margin-bottom: 2.5rem;
		}
	}
	.mandatory-note{
		position: absolute;
		top: 1rem;
		font-weight: $semi-bold;
		font-size: 1.2rem;
		line-height: 1;
		#{$end-direction}: 0;
		@include tablet(){
			top: 1.6rem;
		}
	}
	&__field-wrapper{
		@include make-row();
		justify-content: space-between;
		.form-row{
			
			&.woocommerce-invalid-required-field{
				border-color:$invalid;
			}
		}
		#billing_country_field{
			display: none;
		}
	}
}
.edit-account{
	label{
		@include tablet(){
			font-size: 1.3rem;
		}
	}
}
.woocommerce-additional-fields{
	h3{
		display: none;
	}
	&__field-wrapper{
		.notes{
			border:0;
			label{
				position: relative;
				#{$start-direction}: 0;
				top: 0;
				font-size: 1.8rem;
				display: inline-block;
				margin-bottom: 1.5rem;
				@include tablet(){
					font-size: 1.6rem;
					margin-bottom: 1.2rem;
				}
			}
			.woocommerce-input-wrapper{
				
				display: block;
			}
		}
	}
}
form.gift-cards_form{
	::-webkit-input-placeholder {
		font: inherit;
	}
	::-moz-placeholder { /* Firefox 19+ */
		font: inherit;
	}
	:-ms-input-placeholder { /* IE 10+ */
		font: inherit;
	}
	:-moz-placeholder { /* Firefox 18- */
		font: inherit;
	}
	.ywgc-manual-amount{
		color: inherit;
		height: auto;
		text-align: $start-direction !important;
		width: 100% !important;
		border: 0.1rem solid $primary;
		padding: 1.2rem 1.4rem 1.3rem;
	}
	.ywgc-manual-currency-symbol {
		display: none !important;
	}
	.ywgc_recipient_info_title , .ywgc-sender-info-title{
		margin-top: 4rem;
		@include tablet(){
			margin-top: 1.6rem;
		}
	}
	.ywgc_recipient_info_title{
		@include tablet(){
			margin-top: 4rem;
		}
	}
	.ywgc-sender-name{
		margin-bottom: 2.4rem;
	}
	h5{
		font-size: 2rem;
		line-height: 2.9rem;
		margin-bottom: 2rem;;
		@include tablet(){
			font-size: 1.8rem;
		}
	}
	.ywgc-single-recipient{
		@include make-row();
		justify-content: space-between;
		.ywgc-recipient-name , .ywgc-recipient-email{
			@include make-col(5.59);
			margin-bottom: 0px;
			@include tablet(){
				@include make-col(12);
				margin-bottom: 2.4rem;
			}
		}
	}
	
	.gift-card-content-editor.step-content{
		input , textarea{
			float: none;
			width: 100%;
			font: inherit;
			max-width: 100% !important;
			padding: 1.2rem 1.4rem 1.3rem;
			border: 0.1rem solid $primary;
			
			
		}
		::-webkit-input-placeholder {
			font: inherit;
		}
		::-moz-placeholder { /* Firefox 19+ */
			font: inherit;
		}
		:-ms-input-placeholder { /* IE 10+ */
			font: inherit;
		}
		:-moz-placeholder { /* Firefox 18- */
			font: inherit;
		}
		label{
			display: none;
		}
	}
	
}
.woocommerce-ResetPassword{
	margin:2rem auto 0;
	.form-row{
		max-width: 50%;
		border:0;
		@include tablet(){
			max-width: 100%;
		}
		&.form-row-first{
			border:1px solid $primary;
			margin-top: 3rem;
		}

	}
	.button{
		padding: 0 3rem !important;
	}
}
.number-input-wrap {
	display: flex;
	width: 100%;
	font-size: 2rem;
	box-sizing: border-box;
	@include tablet{
		// height: 4.8rem;
	}
	
	input{
		margin: 0;
		padding: 0;
		z-index: 1;
		width: 100%;
		border: 0px;
		font: inherit;
		resize: none;
		font: inherit;
		color: inherit;
		display: block;
		position: relative;
		text-align: center;
		border-radius: 0px;
		font-weight: inherit;
		text-rendering: auto;
		box-sizing: border-box;
		-webkit-appearance: none;
		background-color: transparent;		
		&:focus{
			outline: none;
			box-shadow: none;
		}
	}
	&__number-btn{
		width: 3rem;
		border: 0px;
		padding: 0px;
		margin: 0px;
		font-weight: inherit;
		transition: all .2s ease-in;
		
	
	}
}
.quantity{
	&--single-product{
		.number-input-wrap{
			input{
				line-height: 5.6rem;
				background-color: $light-bronze;
			}
		}
	}
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
    margin: 0; 
}
input[type=number] {
    -moz-appearance:textfield;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  	color: $text !important;
  	font-size: inherit !important;
  	letter-spacing:inherit;
  	line-height: inherit;
  	font-weight: $normal;
  	font-style:normal !important;
  	@include tablet(){
		
	}
}
::-moz-placeholder { /* Firefox 19+ */
  	color: $text !important;
  	font-size: inherit !important;
  	letter-spacing:inherit;
  	line-height: inherit;
  	font-weight: $normal;
  	font-style:normal !important;
  	@include tablet(){
	
	}
}
:-ms-input-placeholder { /* IE 10+ */
    color: $text !important;
  	font-size: inherit !important;
  	letter-spacing:inherit;
  	line-height: inherit;
  	font-weight: $normal;
  	font-style:normal !important;
  	@include tablet(){
	
	}
}
:-moz-placeholder { /* Firefox 18- */
    color: $text !important;
  	font-size: inherit !important;
  	letter-spacing:inherit;
  	line-height: inherit;
  	font-weight: $normal;
  	font-style:normal !important;
  	@include tablet(){
		
	}
}
::placeholder{
	color: $text !important;
  	font-size: inherit !important;
  	letter-spacing:inherit;
  	line-height: inherit;
  	font-weight: $normal;
  	font-style:normal !important;
  	@include tablet(){
		
	}
}

//waitlist form
.wcwl_elements{
	position: relative;
	.wcwl_email_elements {
		margin-top: 1.9rem;
		input{
			padding: 1.9rem 1.5rem;
			border: 1px solid $primary;
			@include tablet{
				padding: 1.9rem 1.6rem 1.6rem;

			}
		}
	}
	.wcwl_control, .woocommerce_waitlist{
		top:calc(50% - 1.4rem + 1.9rem);
		#{$end-direction}: calc(1.3rem + 1px);
		width: 2.8rem;
		height: 2.8rem;
		font-size: 0px;
		border-radius: 50%;
		position: absolute;
		margin: 0 !important;
	}
	.woocommerce_waitlist{
		top: 0;
		z-index: 1;
		#{$end-direction}: 0;
		background: $bronze url(images/dropdown_waitlist.png) center / 30% no-repeat;

	}
}

.footer{
	div.wpcf7 {
		.wpcf7-form {
			.wpcf7-response-output{
				color: $white;
			}
		}
	}
	
}