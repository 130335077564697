&--index{
    @include make-col(4);
    padding: 0 1.5rem;
    margin-bottom: 6.3rem;
    @include tablet{
        @include make-col(12);
        margin-bottom: 2.1rem;
        padding: 0;
    }
    .btn--absolute{
        z-index: 1;
    }
    &__content-wrap{
        @include make-row();
        position: relative;
        background: linear-gradient(180deg, #FBFAF9 0%, #F6F3F0 100%);
        box-shadow: 0 8px 12px 0 rgba(118,65,0,0.15);
    }
    &__image-wrap{
        @include make-col(12);
        img{
            width: 100%;
            height: 40.8rem;
            object-fit: cover;
            @include tablet{
                height: auto;
            }
        }
    }
    &__text-wrap{
        @include make-col(12);
        padding: 5rem 3.4rem 4.4rem;
        @include tablet{
            padding: 2.2rem 1.3rem 2.7rem;
        }
        h3{
            font-size: 3.2rem;
            line-height: 2.9rem;
            margin-bottom: 2.3rem;
            font-weight: $semi-bold;
            @include tablet{
                font-size: 2.6rem;
                margin-bottom: 1rem;
            }
        }
        p{
            line-height: 2.4rem;
            margin-bottom: 4.5rem;
            @include tablet{
                font-size: 1.6rem;
                margin-bottom: 2.2rem;
            }
        }
    }
    &:first-child{
        @include make-col(12);
        .post--index{
            &__image-wrap{
                @include make-col(7);
                @include tablet{
                    @include make-col(12);
                }
                
            }
            &__text-wrap{
                @include make-col(5);
                padding: 3.6rem 3.4rem 6.7rem;
                padding-#{$end-direction}: 12rem;
                @include tablet{
                    @include make-col(12);
                    padding: 2.2rem 1.3rem 2.7rem;
                }
            }
        }
    } 
    
}