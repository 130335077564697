.shipping{
    &__content-wrap{
        @include make-row();
        margin: 0 auto;
        padding-top: 7.7rem;
        padding-bottom: 8.3rem;
        @include tablet{
            padding-top: 0rem;
            padding-bottom: 3.9rem;
        }
    }
    &__image-wrap{
        @include make-col(3.44);
        @include tablet{
            @include make-col(12);
            margin-bottom: 1.9rem;
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    &__text-wrap{
        @include make-col(7.5);
        padding-#{$start-direction}: 15.2rem;
        @include tablet{
            @include make-col(12);
            padding-#{$start-direction}: 0rem;
        }
        h1,h2{
            font-size: 3.5rem;
            letter-spacing: 0;
            line-height: 5.2rem;
            margin-bottom: 1.7rem;
            font-weight: $semi-bold;
            text-align: $start-direction;
            @include tablet{
                font-size: 2.6rem;
                line-height: 3.8rem;
                margin-bottom: .5rem;
            }
        }
        h2{
            margin-top: 5rem;
            padding-top: 3.19rem;
            border-top: 1px solid $mid_gray;
            @include tablet{
                margin-top: 1.8rem;
                padding-top: 2.6rem;
            }
        }
        p{
            line-height: 2.3rem;
            margin-bottom: 1.2rem;
        }
        .wp-block-group{
            margin-top: 3.4rem;
            display: inline-block;
            padding: 1.5rem 2.4rem;
            background-color: $light-bronze;
            p{
                line-height: 2.8rem;
            }
            a{
                display: inline-block;
                margin-#{$end-direction}: 4.5rem;
                &::before{
                    content: '';
                    width: 1rem;
                    height: 2rem;
                    display: inline-block;
                    vertical-align: middle;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-#{$end-direction}: 1.3rem;
                    background-image: url(images/facebook-page.svg);

                }
                &:first-of-type{
                    &::before{
                        width: 2rem;
                        height: 1.5rem;
                        background-image: url(images/mail.svg);
                    }
                }
            }
        }
    }
}