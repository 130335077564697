&--tag{
	.products{
		@include make-col(12);
		@include make-row();
		margin:3.3rem -1.65rem 0;
		@include tablet(){
			margin:2.7rem -0.55rem 2rem;
			@include make-col(12);
		}
		.product{
			@include make-col(3);
			@include tablet(){
				@include make-col(6);
			}
		}
	}
}
&:not(.shop--tag){
	.products{
		order:3;
		@include make-col(9);
		@include make-row();
		margin:3.3rem -1.65rem 0;
		@include tablet(){
			margin:2.7rem -0.55rem 2rem;
			@include make-col(12);
		}
		&.columns-3{
			.product{
				@include make-col(4);
				@include tablet(){
					@include make-col(6);
				}
			}
		}
	}
}
