&--index{
	list-style: none;
	padding: 0 1.65rem;
	position: relative;
	box-sizing: border-box;
	margin-bottom: 5.9rem;
	@include tablet(){
		padding: 0 0.55rem;
		margin-bottom: 2rem;
	}
	.btn--like{
		display: none;
		position: absolute;
		top: 1.6rem;
		#{$end-direction}: 1.6rem;
		z-index: 4;
		@include tablet(){
			top: 0.9rem;
			display: block;
			#{$end-direction}: 1rem;
			line-height: 1;
		}
		.icon--wishlist{
			@include tablet(){
				width: 1.32rem;
				height: auto;
			}
		}
	
		&.active{
			display: block;
		}
	}
	&__hover-wrap{
		bottom: 0;
		height: 0px;
		width: 100%;
		display: flex;
		cursor: pointer;
		overflow: hidden;
		position: absolute;
		align-items: center;
		flex-direction: column;
		#{$start-direction}: 0px;
		justify-content: space-between;
		background-color: rgba(246,243,240,0.5);
		@include tablet{
			display: none;
		}
		.btn--like{
			display: flex;
			position: relative;
			margin-bottom: 3rem;
			align-items: center;
			&.active{
				display: none;
			}
		}
	}
	&__img-wrap{
		display: block;
		position: relative;
		img{
			width: 100%;
			height: $product-loop-img-height;
			object-fit: cover;
			display: block;
			@include tablet(){
				height: $product-loop-img-height-mobile;
				object-fit: contain ;
			}
		}
		h2{
			text-align: center;
			margin-top: 2rem;
		}
	
			
	}
	.woocommerce-loop-product__title{
		text-align: center;
		font-size: 1.8rem;
		line-height: 2.9rem;
		margin-top: 2rem;
		@include tablet(){
			font-size: 1.2rem;
			line-height: 2rem;
			margin:2.7rem 0 0rem;
		}
	}
	&__tags{
		font-size: 1.8rem;
		line-height: 2.9rem;
		@include tablet{
			font-size: 1.2rem;
			line-height: 2rem;
		}
	}
	&__variations{
		font-size: 1.5rem;
		
	}
	&__attributes{
		text-align: center;
		@include tablet{
			font-size: 1.2rem;
			line-height: 2rem;
		}
		span{
			&::after{
				content: ' | ';
			}
			&:last-of-type::after{
				content: '';
			}
		}
	}
	.price{
		text-align: center;
    	display: block;
		margin-top: 1rem;
    	@include tablet(){
			margin-top: 0.3rem;
    		line-height: 1.4rem;
    	}
	}
}