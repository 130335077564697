.dgwt-wcas-search-wrapp{
	display: none;
	z-index: 1;
	margin-#{$end-direction}: -4rem;
	@include tablet(){
		display: block;
		margin-#{$end-direction}: 0rem;
	}
	&.active{
		display: block;
	}
	.dgwt-wcas-search-form{
		&:focus{

		}
		.dgwt-wcas-sf-wrapp{
			svg{
				fill: $primary;
				#{$end-direction}: 12px;
				#{$start-direction}: auto;
				path{
					fill: $primary;
				}
			}
			.dgwt-wcas-search-input{
				border:0;
				border-radius: 0;
				font-size: inherit;
				padding: 1rem 5rem !important;
				text-align: $start-direction;
				background-color: $search-bg-color;
				margin-#{$start-direction}: auto;

				@include tablet(){
					padding:1rem 2rem !important;
				}
				&:focus{
					box-shadow:none;
					background-color: $search-bg-color;
				}
			}
			.dgwt-wcas-preloader{
				svg{
					width: 2rem;
					height: 2rem;
				}
			}
		}
	}
}
.dgwt-wcas-suggestion{
	text-align: $start-direction;
	.dgwt-wcas-st-breadcrumbs{
		display: none;
	}
}
.dgwt-wcas-content-wrapp{
	text-align:  $start-direction;
	padding-#{$start-direction}: 3rem;
	span{
		font-size: 1.6rem !important;
	}
}