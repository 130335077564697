.product{
	@import "product/index";
	@import "product/single";
	@import "product/addtional-scent";
	// @import "product/giftcard";
}
ins{
	background: none;
	.woocommerce-Price-amount{
		color: $bronze;
		background: none;
		
	}
}

.woocommerce-Price-amount{
	font-size: 2rem;
	line-height: 2.9rem;
	@include tablet{
		font-size: 1.4rem;
		line-height: 2rem;;
	}
}