.gallery{
    &__content-wrap{
        padding-top: 9rem;
        @include tablet{
            padding-top: 4.7rem;
        }
    }
    .home-cta-slide{
        @include make-row();
        color: $white;
        &__inner{
            @include make-col(6);
            padding: 15.9rem 15% 17.6rem;
            text-align: center;
            @include tablet{
                @include make-col(12);
                min-height: 25.6rem;
                padding: 3.9rem 7.5rem 4.4rem;
            }
            img{
                width: 100%;
                height: auto;
                display: block;
                margin: 0 auto 5.4rem;
                @include tablet{
                    margin: 0 auto 2.7rem;
                }
            }
            p{
                margin: 0 auto;
                font-size: 3rem;
                line-height: 3.84rem;
                letter-spacing: -.047rem;
                @include tablet{
                    font-size: 1.7rem;
                    line-height: 1.94rem;
                    letter-spacing: -0.027rem;
                }
            }
            a{
                @extend .btn--primary;
                margin-top: 6.5rem;
                @include tablet{
                    margin-top: 1rem;
                }
            }
        }
    }
}