.size-table{
	padding-right: 13.7rem;
	display: flex;
    align-items: center;
    @include tablet(){
    	padding-right:$mobile-side-padding;
    	justify-content: center;
    }
	.table{
		border-collapse: collapse;
		border-right:1px solid $gray;
		border-top:1px solid $gray;
		border-bottom:1px solid $gray;
		tr{
			&:first-child{
				td{
					padding-top: 2rem;
					@include tablet(){
						padding-top: 1.5rem;
					}
				}
			}
			&:last-child{
				td{
					padding-bottom: 2.4rem;
					@include tablet(){
						padding-bottom: 1.5rem;
					}
				}	
			}
		}
		td{
			
			border-left:1px solid $gray;
			padding: 1.5rem 2.3rem;
			@include tablet(){
				padding:1rem;
			}
			&:last-child(){

			}
		}
		&__row-title{
			text-align: right;
			font-weight: $semi-bold;
			@include tablet(){
				font-size: 1rem;
				font-weight: $bold;
			}
		}
		&__value{
			text-align: center;
			@include tablet(){
				font-size: 1.3rem;
			}
		}
	}
}