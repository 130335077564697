&--single{
    @import 'single/banner';
    @import 'single/main';
    @import 'single/additional-scents';
    @import 'single/image-text';

    &__section{
        &:last-child{
            margin-bottom: 15.8rem;
            @include tablet{
                margin-bottom: 5.8rem;
            }
        }
    }
}