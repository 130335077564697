// Bootstrap Imports
@import "../bootstrap/mixins/_breakpoints";
@import "../bootstrap/_variables";
@import "../bootstrap/mixins/_grid";


@mixin mobile(){//576
  @include media-breakpoint-down(xs){
    @content;
  }
}
@mixin mobile-landscpae(){//768
	@include media-breakpoint-down(sm){
		@content;
	}
}
@mixin tablet(){//992
	@include media-breakpoint-down(md){
		@content;
	}
}
@mixin landscape(){//1024
	@include media-breakpoint-down(lg){
		@content;
	}
}

@mixin desktop(){//1440
  @include media-breakpoint-down(xl){
    @content;
  }
}
@mixin retina(){//1440
  @include media-breakpoint-down(xxl){
    @content;
  }
}

@mixin full_svg{
	svg,img{
	  width: 100%;
	  height: 100%;
	  object-fit: contain;
	  object-position: center;
	}
}

@mixin full-col-tablet{
	@include tablet{
		@include make-col(12);
	}
}

@mixin gradient_backgrround{
	width: 100%;
	color: $white;
	h1,h2,h3,h4,h5,h6,p,a{
		z-index: 1;
		position: relative;
	}
	&::after{
		content: '';
		position: absolute;
		bottom: 0;
		#{$end-direction}: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: multiply;
		background: linear-gradient(180deg, rgba(170,151,128,0) 0%, #AA9780 100%);
		

	}
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
  &:focus{
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		#{$end-direction}: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}


// Config Imports
@import "changes";
@import "colors";

@import "typography";
@import "form";
@import "buttons";
@import "dropdown";
@import "accordion";
@import "popups";
@import "icons";
@import "tags";
@import "slider";
@import "animations";
@import "keyframes";
@import "nav";




