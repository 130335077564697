.instagram{
	margin-bottom: 10rem;
	@include tablet(){
		margin-bottom: 4rem;
	}
	&__insta-wrap{
		.sbi_item{
			@include tablet(){
				width: 50% !important;
			}
		}
	}
	&__text-wrap{
		margin-bottom: 5rem;
		text-align: center;
		font-size: 3.5rem;
		font-weight: $semi-bold;
		@include tablet(){
			font-size: 2rem;
			margin-bottom: 2rem;
			font-weight: $normal;
		}
	}
	&__title{
		direction: ltr;		
	}
	&__link-wrap{
		margin-top: 6.1rem;
		text-align: center;
		@include tablet(){
			margin-top: 4rem;
		}
		
		.btn{
			display: inline-block;
			box-sizing: border-box;
			white-space: nowrap;
			text-transform: uppercase;
			font-size: 1.8rem;
			line-height: 4rem;
			padding: 1.6rem 4.3rem 1.2rem;
			@include tablet(){
				line-height: 4rem;
				padding: 0.5rem 1.9rem;
				display: block;
				width: 100%;
			}
		}
	}
}