$side_padding: 0;
$mobile_side_padding:2.2rem;
html {

}
.yith-wcwl-add-to-wishlist{
	display: none;
}
body{
	margin: 0;
	width: 100%;
	
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:focus{
  	outline: none;
  }
}

a{
	text-decoration: none;
	color: inherit;
}
ul{
	list-style: none;
	margin:0;
	padding:0;
}
.main{
	padding-top: var(--header-height);
	padding-top: calc(var(--simply-club-height) + var(--header-height));
	min-height: calc(100vh - var(--header-height) - var(--footer-height));
	min-height: calc(100vh - var(--header-height) - var(--footer-height) - var(--simply-club-height));
	&.menu-open{
	}
}
.content-wrap{
	max-width: 176rem;
	padding-#{$start-direction}: $side_padding;
	padding-#{$end-direction}: $side_padding;
	margin: 0 auto;
	box-sizing: border-box;
	&--full{
		max-width: 100%;
	}
	&--medium{
		max-width: 140rem;
	}
	&--small{
		max-width: 116rem;
	}
	&--narrow{
		max-width: 68.2rem;
	}
	@include tablet(){
		padding-#{$start-direction}: $mobile_side_padding;
		padding-#{$end-direction}: $mobile_side_padding;
		max-width: 100%;
	}
}
.desktop{
	@include tablet(){
		display: none;
	}
}
.mobile{
	display: none;
	@include tablet(){
		display: block;
	}
}
.relative-wrap{
	position: relative;
}

.image-wrap-hover-img-grow{
	overflow: hidden;
	img{
		transition: transform 1.2s cubic-bezier(.16,1,.3,1);
	}
}
.blur{
	filter: blur(1px);
}

a.js-dgwt-wcas-suggestion-more{
	display: none;
}