.icon {
	display: inline-block;
	font-style: normal;
	background-repeat:no-repeat;
	background-position:center;
	@include full_svg;
	&--cart{
		width: 2.5rem;
		height: 2.7rem;
	}
	&--user{
		width: 2rem;
		height: 2.1rem;
		@include tablet(){
			margin-#{$start-direction}: 0.8rem;
		}
	}
	&--wishlist{
		width: 2.4rem;
		height: 2rem;
		svg{
			#DT{
				stroke:$primary;
			}
		}
		&.active{
            svg{
                #DT, #HP{
                    fill: $primary;
                }
            }
        }
	}
	&--payment{
		width: 3.8rem;
		height: auto;
		margin-#{$end-direction}:1rem;
		@include tablet(){
			margin-#{$end-direction}: 0.4rem;
			width: 3.2rem;
		}
		&:last-child(){
			margin:0;
		}

	}
	&--social{
		max-width: 2.4rem;
		max-height: 2rem;
		width: auto;
		height: auto;
	}
	&--search{
		width: 2.3rem;
		height: 2.3rem;
	}
	&--popup-logo{
		margin: 3.2rem auto;
	    display: block;
	    width: $desktop-popup-logo-width;
	    height: $desktop-popup-logo-height;
	    @include tablet(){
	    	margin: 1.5rem auto;
	    	width: $mobile-popup-logo-width;
	    	height: $mobile-popup-logo-height;
	    }
	}
	&--header-notice{
		margin:0 1.5rem;
		width: 1rem;
		height: 1rem;
		@include tablet(){
			width: 0.8rem;
			height: 0.8rem;
			margin: 0 1.2rem;
		}
	}
	&--hamburger{
		@include tablet(){
			width: 2rem;
			height: 1.7rem;
		}
	}
	&--hamburger-close{
		@include tablet(){
			width: 1.6rem;
			height: 1.6rem;
		}
	}
	&--close{
		display: block;
		svg{
			#Sign-Up{
				stroke: $primary;
			}
		}
	}
	&--popup-close{
		display: block;
		height: 2.3rem;
		width: 2.3rem;
		@include tablet(){
			height: 1.7rem;
			width: 1.7rem;
		}
	}
	&--product-plus{
		width: 5.5rem;
		height: 5.5rem;
		display: block;
		margin-top: calc(50% - 2.75rem);
		background: url(images/product-plus.svg) center / contain no-repeat;
		@include tablet{
			margin-top: 50%;
		}
		
	}
	&--instagram-section{
		width: 3.4rem;
		height: 3.4rem;
		margin-bottom: 2rem;
		@include tablet{
			width: 2.4rem;
			height: 2.4rem;
			margin-bottom: 1rem;
		}
	}
}