.about{
	&__content-wrap{
		@include make-row();
		margin: 0 auto;
		padding-top: 10rem;
		padding-bottom: 6rem;
		@include tablet{
			padding-top: 0;
		}
	}
	&__images-wrap{
		position: relative;
		@include make-col(6.7);
		@include tablet{
			@include make-col(12);
		}
		
	}
	&__text-wrap{
		@include make-col(4);
		padding-top: 35rem;
		line-height: 2.6rem;
		padding-#{$start-direction}: 3.5rem;
		@include tablet{
			@include make-col(12);
			padding-top: 0rem;
			letter-spacing: -0.007rem;
			padding-#{$start-direction}: 0rem;
		}
		h1{
			font-size: 3.5rem;
			font-weight: $bold;
			line-height: 9.3rem;
			@include tablet{
				font-size: 2.6rem;
				line-height: 5.3rem; 
				letter-spacing: normal;
			}
		}
	}
	.rotating-stamp{
		top: -5rem;
		text-align: center;
		position: relative;
		margin-bottom: -5rem;
		padding-#{$start-direction}: 9rem;
		@include tablet{
			padding-#{$start-direction}: 15rem;
		}
	}
	&__image-wrap{
		
		&:nth-child(1){
			top: 22.5rem;
			z-index: 1;
			position: absolute;
			#{$end-direction}: -28rem;
			@include tablet{
				top: 9.8rem;
				width: 100%;
				#{$end-direction}: 0rem;
			}
			img{
				width: 77.1rem;
				@include tablet{
					width: 100%;;
					height: 2.6rem;
					object-fit: contain;
				}
			}
		}
		&:nth-child(2){
			text-align: $end-direction;
			img{
				width: 80.25rem;
				@include tablet{
					width: 100%;
				}
			}
		}
		&:nth-child(3){
			top: -7rem;
			position: relative;
			margin-bottom: -7rem;
			@include tablet{
				top: -2.6rem;
				margin-bottom: -2.6rem;
			}
			img{
				width: 53rem;
				@include tablet{
					width: 20.8rem;
				}
			}
		}
		
	}
}