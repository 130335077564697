&--single{
	.main-info{
		padding-top: 3.7rem;
		@include tablet(){
			padding-top: 0;
		}
		&__content-wrap{
			@include make-row();
			margin:0 auto;
			@include tablet(){
				padding:0;
			}
		}
		
	}
	&__title{
		
		font-size: 3.2rem;
		line-height: 2.9rem;
		letter-spacing: normal;
		margin-bottom: 1.7rem;
		font-weight: $semi-bold;
		@include tablet(){
			font-size: 2.6rem;
			margin-bottom: .6rem;
		}
	}
	&__title-wrap{
		border-bottom: 2px solid $mid_gray;
		padding-bottom: 2.8rem;
		margin-bottom: 2rem;
	}
	&__tag{
		font-size: 1.7rem;
		line-height: 1.3rem;
		font-weight: $normal;
		margin-bottom: 1.4rem;
	}
	&__price{
		.woocommerce-Price-amount{
			font-size: 2rem;
		}
	}
	&__buying-options{
		@include make-row();
		margin-bottom: 2.85rem;
		align-items: flex-end;

		@include tablet(){
			margin-bottom: 2.5rem;
		}
	}
	&__add-to-cart-wrap{
		@include make-col(6);
		@include make-col-offset(3);
		&.invalid{
			.product--single__validation{
				display: inline-block;
				&.in-cart{
					display: none;
				}
			}
		}
	}
	&__quntity-wrap{
		@include make-col(3);
		h3{
			font-size: 1.7rem;
			font-weight: $normal;
			margin-bottom: 1.5rem;;
		}
	}
	&__validation-info{
		margin-top: 2.4rem;
		@include make-col(12);
	}
	&__btn-comment{
		font-size: 1.5rem;
		margin-top: 1.2rem;
	}
	&__breadcrumbs{
		@include make-col(12);
		@include tablet(){
			display: none;
		}
		.woocommerce-breadcrumb{
			margin-bottom:3.4rem;
			white-space: pre-wrap;
			color: $breadcrumbs-color;
			font-size: 1.5rem;
			line-height: 2;
		}
	}
	.gallery{
		padding-top: 2.2rem;
		@include make-col(835,1400);
		@include make-col-offset(120,1400);
		@include tablet(){
			@include make-col(12);
			@include make-col-offset(0);
		}
		img{
			width: 100%;
			height: auto;
		}
		&__wrap{
			@include make-row();
		}
		&__nav-wrap{
			@include make-col(206, 835);
			@include make-col-offset(33,835);
			@include tablet(){
				display: none;
			}
		}
		&__main-img-wrap{
			@include make-col(596,835);
			position: relative;
			@include tablet(){
				@include make-col(12);
			}
			.btn--like{
				position: absolute;
				#{$end-direction}: 2.4rem;
				top: 2.4rem;
				span{
					display: none;
				}
				@include tablet(){
					#{$end-direction}: $mobile_side_padding;
					top: 2rem;
				}
			}
		}
	}
	&__textual-wrap{
		@include make-col(445,1400);
		@include tablet(){
			@include make-col(12);
			margin-top: 2.5rem;
			padding-right: $mobile_side_padding;
			padding-left: $mobile_side_padding;
		}
		.variations{
			border-bottom: 2px solid $mid_gray;
			
			margin-bottom:2rem;
			padding: 0;
			@include tablet(){
				border-width: 1px;
				padding: 2.3rem 0 0;
				margin: 2.4rem 0 3rem;
			}
			&__container{
				margin-bottom: 1.9rem;
				.btn--size-variation{
					font-size: 2rem;
					padding:1.1rem;
					
					&:first-child(){
       					margin-#{$start-direction}: 0;
       				}
				}
				.btn--color-variation{
					width: 2.8rem;
					height: 2.8rem;
				}
			}
			&__label{
				font-size: 1.7rem;
				font-weight: $normal;
				margin-bottom: .7rem;;
			}
			
			.out-of-stock{
				padding-bottom: 2.8rem;
				@include tablet(){
					padding-bottom: 2.3rem;
				}
			}
		}
	}

	&__validation{
		color:$invalid;
		font-size: inherit;
		font-weight: $semi-bold;
		display: none;
		margin-top: 1rem;
		@include tablet(){
			margin:1rem 0 0 0;
		}
	}
	&__waitlist-form-wrap{
		margin-top: 1.9rem;
		margin-bottom: 2rem;
		h4{
			font-weight: normal;
		}
		.wcwl_intro{
			p{
				line-height: 2.9rem;
			}
		}
	}
	.additional-scents{
		margin: 14rem 0 1.7rem;
		background-color: $light-bronze;
		@include tablet{
			margin: 4.8rem 0 3.4rem;
		}
		&__content-wrap{
			padding-top: 3.3rem;
			padding-bottom: 8.2rem;
			@include tablet{
				padding-top: 3.1rem;
				padding-bottom: 2.1rem;
			}
		}
		h2{
			margin-bottom: 5.4rem;
			@include tablet{
				margin-bottom: 3rem;
			}
		}
		&__container{
			@include tablet{
				@include make-row();
				margin: 0 -1.1rem;
			}
		}
	}
	.upsale-products{
		margin:10.7rem 0 3rem;
		@include tablet(){
			margin:3rem 0 0;
		}
		h2{
			margin-bottom: 7.9rem;
			@include tablet(){
				margin-bottom: 2rem;
			}
		}
		ul{
			@include make-row();
			&.columns-4{
				margin:0 0-1.65rem;
				@include tablet(){
					margin:0 -0.55rem;
				}
				.product{
					@include make-col(3);
					padding: 0 0.55rem;
					@include tablet(){
						@include make-col(6);
						margin-bottom: 3rem;
					}
					.badge--single{
						@extend .badge--index;
					}
				}
			}
		}
	}
}