$padding-top: 7.7rem;
.carousel-banner{
	padding-top: $padding-top;
	@include tablet{
		padding-top: 0px;
	}
	&__content-wrap{
		height: 74.5rem;
		position: relative;
		@include tablet {
			height: 22.9rem;
			max-width: 32rem;
		}
	
	}
	.slider{
		width: 100%;
		height: 100%;
		top: 0;
		#{$start-direction}: 0;
		// top: $padding-top;
		position: absolute;

		.slick-list, .slick-track{
			height: 100%;
			width: 100%;
		}
	}
	.banner-slide{
		&__inner{
			height: 100%;;
		}
		&__content-wrap{
			height: 100%;
			display: flex;
			align-items: flex-end;
		}
		&__text-wrap{
			@include gradient_backgrround;
			font-size: 3rem;
			line-height: 3.74rem;
			padding: 6rem 8.7rem;
			@include tablet{
				font-size: 1.7rem;
				letter-spacing: 0;
				line-height: 1.94rem;
				text-align: center;
				padding: 2.2rem 5.6rem 2.65rem;
			}
			
			a{
				@extend .btn--primary;
				margin-top: 2.3rem;
				@include tablet{
					margin-top: 1.25rem;
				}
			}
		}
	}
	&__image-wrap{
		width: 99rem;
		margin: 0 auto;
		height: 17.8rem;
		position: relative;
		padding-top: 28.4rem;
		@include full_svg;
		@include tablet{
			width: 23.8rem;
			height: 4.3rem;
			padding-top: 3.4rem;
		}
		
	}
	

}