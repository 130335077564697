//** typography ** 
	$font: 'RAGSans', sans-serif;



//** colors **
	//general
		$text:#212121;
		$bronze:#9C7341;
		$light-bronze:#F6F3F0;
		$mid_gray:  #DBDBDB;
		$white: #ffffff;
	
	//nav
		$main-nav-item-color:$text;
		$main-nav-bg-color:$white;

	//popups
		$cart-popup-header-bg-color:$light-bronze;

	//components
		$dropdown-bg-color:$light-bronze;
		$search-bg-color:$light-bronze;

	//homepage
		$hp-shop-cat-link-color:$bronze;

	//category
		$cat-hero-mask-color:rgba(0, 11, 22, 0);
		$cat-hero-title-color:$white;
		$cat-btn-color:$bronze;
		$filters-bg-color:$light-bronze;

	//product
		$breadcrumbs-color:#A7ABB4;
		$sale-price-color:$text;

	//checkout
		$checkout-bg: #f5f3ef;

	//account
		$account-bg-color:#F7F5F2;



//** logo size **
	//header
		$desktop-header-logo-width: 7rem;
		$mobile-header-logo-width:3.7rem;
	
	//footer
		$desktop-footer-logo-width: 36rem;
		$mobile-footer-logo-width:20.7rem;
	//popup
		$desktop-popup-logo-width: 25.2rem;
		$desktop-popup-logo-height: 7.1rem;
		$mobile-popup-logo-width:15.9rem;
		$mobile-popup-logo-height:4.5rem;

//** Image sizes **
	//HP cat thumb images 
		$cat-thumb-col-3-img-wrap-height:auto;
		$cat-thumb-col-4-img-wrap-height:auto;
		$cat-thumb-col-3-img-wrap-height-mobile:auto;
		$cat-thumb-col-4-img-wrap-height-mobile:auto;
		$cat-thumb-img-height:auto;
		$cat-thumb-text-justify-content-mobile:center;

	//product loop thumb image
		$product-loop-img-height: 38rem;
		$product-loop-img-height-mobile:15.3rem;

//** Text size **
	//HP
		$instagram-title-size-mobile:3.3rem;


//** other manual changes **
//images folder
	//change + and check svg for quick add on cat page
	//change accordion arrow svg color
	//change slick down
	//change down arrow
	//change hamburger and close hamburger
	//change coupon_btn.svg


//sliders
	//homepage products silder is different - Hagar has different JS settings and arrows appear.
	//Header banner is different - change header.php and double the banner slides for it to move

//MISC
	// hagar has some CSS in checkout form #billing_city_field to support plugin
	//change variations.php template (inside template-parts/tr_mozi_woocommerce) - Hagar is without the word "בחרי" both in index and single product