.accordion{
	
	&__content-wrap{
		height: 0;
		overflow: hidden;
	}
	&__collapse{
        height: 0;
        overflow: hidden;
        &.appear{
            overflow: visible;
        }
    }
	&__btn{
		width: 100%;
		display: flex;
		position: relative;
		align-items: center;
		justify-content: space-between;
		&::after{
			content:'';
			display: block;
			transition: transform 0.2s ease-in-out;
		} 
	}
	&--product{
		&__item{
			padding: 1.65rem 0;
			border-top:1px solid $mid_gray;
			&:last-child(){
				border-bottom:1px solid $mid_gray;
			}
		}
		&__body{
			padding-top:1.4rem;
			font-size: 1.7rem;
			line-height: 2.3rem;
		}
		&__btn{
			font-size: 1.7rem;
			line-height: 2.1rem;
			font-weight: $normal;
			&::after{
				width: 1.5rem;
				height: 0.7rem;
				background: url('images/accordion_arrow.svg') center / 99% no-repeat;
			} 
			&.open{
				&::after{
					transform: rotate(180deg);
				}
			}
		}
	}
	&--filter{
		margin-#{$end-direction}: 1.3rem;
		&__btn{
			font-size: 1.5rem;
			line-height: 2rem;
			font-weight: $bold;
			&::after{
				width: 1rem;
				height: .6rem;
				margin-#{$start-direction}: 1rem;
				background: url('images/accordion_arrow.svg') center / 99% no-repeat;
			} 
			&.open{
				&::after{
					transform: rotate(180deg);
				}
			}
		}
		&__collapse{
			z-index: 4;
			position: absolute;
		}
		&__body{
			background: $white;
			border: 1px solid;
			padding: .5rem 1rem;
			line-height: 2;
		}
		
	}
	&--sale-point{
		&__item{
			padding-top: 4rem;
			padding-bottom: 3rem;;
			border-bottom: 1px solid #DBDBDB;
			@include tablet{
				padding-top: 2.5rem;
				padding-bottom: 2rem;
			}
			&:first-child{
				border-top: 1px solid #DBDBDB;
			}
		
			
		}
		&__body{
			padding-top:1.1rem;
			line-height: 2.6rem;
			.store{
				margin-bottom: 1rem;
			}
		}
		&__btn{
			line-height: 1;
			font-size: 3.3rem;
			font-weight: $semi-bold;
			@include tablet{
				font-size: 2.7rem;
			}
			&::after{
				content:'';
				width: 2.4rem;
				height: 2.4rem;
				background: url('images/accordion_plus.svg') center / 99% no-repeat;
			}
			&.open{
				&::after{
					transform: rotate(45deg);
				}
			}
		}
	}
}