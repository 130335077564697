.product-slider{
	margin-bottom: 10.6rem;
	@include tablet(){
		margin-bottom: 7.5rem;
	}
	&__content-wrap{
		padding-top: 10rem;
		padding-right: 14.5rem;
		padding-left: 14.5rem;
		@include tablet{
			padding: 0;
			padding-top: 4.2rem;
		}
	}
	
	

	&__title-wrap{
		margin-bottom: 6.1rem;
		@include tablet{
			margin-bottom: 2.5rem;
		}
	}
	&__slider-wrap{
		@include tablet{
			padding-#{$start-direction}: 2rem;
		}
	}
}