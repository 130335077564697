.post{
	&__content-wrap{
		padding:calc(var(--header-height) + 11.8rem) 0 5.8rem;
		border-bottom: 1px solid #E5E5E5;
		@include tablet(){
			padding:calc(var(--header-height) + 3rem) 0 5.8rem;
		}
	}
	&__title{
		margin-bottom: 2.2rem;
		line-height: normal;
		@include tablet(){
			margin-bottom: 3rem;
			font-size: 3.4rem;
			line-height: 3.8rem;
		}
	}
	&__img{
		width: 100%;
		height: auto;
		display: block;
		margin-bottom: 5.2rem;
		@include tablet(){
			margin-bottom: 3rem;
		}
	}
	&__content{
		padding-top: 1.6rem;
		iframe{
			width: 100%;
			&::before{
				content:'hi';
				display: block;
			}

		}
	}
	&__video{
		width: 100%;
		height: 74rem;
		display: block;
		margin: 4.2rem 0 7rem;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		@include tablet(){
			height: 21.4rem;
			margin:3rem 0;
		}
	}
}