.tranquilo-login{
    &__text-wrap{
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 2.3rem;
        text-align: center;
        a, button{
            font-weight: bold;
            text-decoration: underline;
        }
    }
}

.forgot-password{
    &__main-content{
        min-height: calc(100vh - var(--footer-height) - var(--header-height));
    }
    &__title{
        margin: 4rem 0;

    }
    &__text-wrap{
        width: 40rem;
    }
    .tranquilo-login{
        &__login-form{
            margin: 4rem 0 ;
        }
    }
}