.price{
	line-height: 2.9rem;
	@include tablet(){
		font-size: 1.4rem;
		line-height: 1.4rem;
	}
	ins{
		text-decoration: none;
		font-weight: $bold;
		color: $sale-price-color;
	}
}