.not-found{
	&__content-wrap{
		min-height: calc(100vh - var(--footer-height) - var(--header-height));
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
	}
	&__title{
		font-size:10rem;
		margin-top: 3rem;
		font-family: $font;
		line-height: normal;
	}
	&__logo{
		width:25rem;
		height:auto;
		@include tablet(){
			width:10rem;
		}
	}
	&__btn{
		margin: 3rem 0 10rem;
		font-family: $font;
		padding: 0 2rem;
	}
}