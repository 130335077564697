.blog-archive{
    &__container{
        @include make-row();
        margin: 0 -1.5rem;
        padding-bottom: 5rem;
        @include tablet{
            margin: 0 auto;
        }
    }
    &__content-wrap{
        padding-top: 8rem;
        @include tablet{
            padding-top: 2.2rem;
        }
    }
    &__title-wrap{
        h1{
            font-size: 3.5rem;
            line-height: 5.2rem;
            text-align: center;
            margin-bottom: 5.6rem;
            font-weight: $semi-bold;
            @include tablet{
                font-size: 2.6rem;
                line-height: 3.8rem;
                margin-bottom: 2.2rem;
            }
        }
    }
}