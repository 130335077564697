.shop{
	@import "shop/woocommerce-products-header";
	@import "shop/active-filters";
	@import "shop/filters";
	@import "shop/products";
	
	&__main-content{
		&>.products{
			margin: 0 auto;
		}
	}
	&__content-wrap{
		@include make-row();
		margin: 0 auto;
		// flex-direction: column;
		&--empty-loop{
			
			.woocommerce-info{
				order:3;
				@include make-col(9);
				@extend .page-title;
				@extend .page-title--smaller;
				margin: 4rem 0;
				@include tablet(){
					margin: 2rem 0;
				}
			}
		}
	}
	
}