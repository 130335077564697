.woocommerce-products-header{
	display: flex;
	height: 28.2rem !important;
	margin-top: 7.8rem !important;
	position: relative;
	align-items: center;
	margin-bottom: 3.5rem;
	justify-content: center;
	@extend .content-wrap;
	@include tablet(){
		height: 14rem !important;
		margin-top: 0 !important;
		margin-bottom: 1.4rem;
	}
	h1{
		color: $cat-hero-title-color;
		font-size: 8.8rem;
		font-weight: $semi-bold;
		@include tablet{
			font-size: 2.6rem;
		}
	}
	
	&__bg{
		position: absolute;
		top: 0;
		
		width: 100%;
		height: 100%;
		z-index: -1;
		#{$end-direction}: 0;
		@include tablet{
			width: calc(100% - #{$mobile_side_padding} - #{$mobile_side_padding});
			#{$end-direction}: $mobile_side_padding;
		}
	}
	&__image-wrap{
		@extend .woocommerce-products-header__bg;
		z-index: -2;
		@include full_svg;
		img{
			object-fit: cover;
		}
	}
	&__mask{
		background-color:$cat-hero-mask-color;
		&--dark{
			background-color: $bronze;
		}
	}
}