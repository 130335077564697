&__active-filters-wrap{
    order: 1;
    @include make-col(9);
    @include make-col-offset(3);
    @include tablet{
        order: 2;
        @include make-col(12);
        margin: .4rem 0 3.4rem;
        @include make-col-offset(0);
    }
    button{
        padding: 1rem 1.2rem .5rem;
        font-size: 1.6rem;
        line-height: 1;
        margin-#{$end-direction}: 3rem;
        padding-#{$end-direction}: 2.5rem;
        background: $light-bronze url(images/exit.svg) 1.1rem center / .7rem no-repeat;
        @include tablet{
            font-size: 1.4rem;
            margin-#{$end-direction}: 1rem;
            padding: .5rem .8rem .3rem;
            padding-#{$end-direction}: 2.3rem;

        }
    }
}