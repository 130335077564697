.general{
	&__content-wrap{
		height: 100%;
		padding:8.65rem 0 9rem;
		line-height: 2.8rem;
		@include tablet(){
			padding:3.2rem $mobile-side-padding 5.7rem;
		}
	}
	&__title{
		margin-bottom: 4rem;
		text-align: center;
		@include tablet(){
			margin-bottom: 2.5rem;
			font-size: 2.4rem;
		}
	}
}