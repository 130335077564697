.shop-categories{
	&__content-wrap{
		padding-top: 6.1rem;
		@include tablet{
			padding-top: 2.1rem;
		}
	}
	&__title-wrap{
		h2{
			margin-bottom: 5rem;
			@include tablet{
				margin-bottom: 2rem;
			}
		}
	}
	&__categories-wrap{
		@include make-row();
		margin:0 -1.3rem;
		@include tablet(){
			margin:0 -0.55rem;
		}
	}
	.shop-category{
		@include make-col(3);
		box-sizing: border-box;
		padding: 0 1.3rem;
		margin-bottom: 3rem;
		@include tablet(){
			@include make-col(6);
			margin-bottom: 1.2rem;
			padding: 0;
			padding: 0 0.55rem;
		}
		&__content-wrap{
			position: relative;
			&:hover{
				h3{
					text-decoration: underline;
				}
				img{
					transform: scale(1.1);
				}
			}

		}
		&__image-wrap{
			width: 100%;
			overflow: hidden;
			height: 42rem;
			
			@include full_svg;
			@extend .image-wrap-hover-img-grow;
			@include tablet{
				height: 15.4rem;
			}
			img{
				display: block;
				object-fit: cover;
				object-position: top;
			}
		}
		&__text-wrap{
			@include gradient_backgrround;
			bottom: 0;
			display: flex;
			height: 23rem;
			position: absolute;
			align-items: flex-end;
			#{$start-direction}: 0;
			padding: 2.2rem 1.4rem;
			box-sizing: border-box;
			justify-content: center;

			@include tablet{
				height: 15.4rem;
				padding: 0rem 1rem;
			}

			h3{
				line-height: 1.4;
				font-size: 1.9rem;
				padding-bottom: 1.44rem;
				letter-spacing: .1rem;
				font-weight: $semi-bold;
				@include tablet{
					font-size: 1.6rem;
					line-height: 1.4;
					padding-bottom: 1.46rem;
				}
				
			}
		}
	}
	
}