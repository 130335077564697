.fade-in-up-wrap{
	&>.fade-in-up{
		opacity: 0;
		transition: opacity .3s ease-in , transform .3s ease-in;
		transform: translateY(2rem);
		
		&--async{
			@for $i from 0 to 16{
				$j: $i + 1;
				&:nth-child(#{$j }){
					transition-delay: calc(0.3*#{$i}s);
					
				}
			}
		}
	}
	&.active{
		&>.fade-in-up{
			opacity: 1;
			transform: translateY(0rem);
		}
	}
}
@keyframes fadeIn {
  from {
    opacity: 0;
  
  }

  to {
    opacity: 1;
    
  }
}
@keyframes fadeInRight {
  from {
  	transform: translateX(200%);

  }

  to {
  	transform: translateX(0);
  }
}
@keyframes fadeInRightGentle {
  from {
  	// transform: translateX(100px);
  	opacity: 0;

  }

  to {
  	// transform: translateX(0);
  	opacity: 1;
  }
}
@keyframes fadeInLeft {
  from {
  	transform: translateX(0);
    opacity: 0;
  
  }

  to {
  	transform: translateX(0);
    opacity: 1;
    
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes moveSlick {
  0% {
    transform:translateX(0);
  }
  50%{
     transform:translateX(30px);
  }
  100%{
    transform:translateX(0);
  }
}
.break-char{
  // @include tablet{
  //   opacity: 0;
  //   transition: opacity .3s ease-in , transform .3s ease-in;;
  //   transform: translateY(2rem);
  // }
  &__node{
    opacity: 0;
    transition: opacity .1s ease-in;
    
    @for $i from 0 to 1500{
      $j: $i + 1;
      &:nth-child(#{$j }){
        transition-delay: calc(0.1*#{$i}s);
      }
    }
    
  }
  &.active{
    // @include tablet{
    //   opacity: 1;
    //   transform: translateY(0rem);
    // }
    .break-char__node{
      opacity: 1;
    }
  }
}
.fade-in-up{
  opacity: 0;
  transform: translateY(2rem);
  transition: all .7s ease-in;
  &--animated{
    opacity: 1;
    transform: translateY(0px);
  }
}
.fade-in-up-wrap{
	&>.fade-in-up{
		opacity: 0;
		transition: opacity .3s ease-in , transform .3s ease-in;
		transform: translateY(2rem);
		
		&--async{
			@for $i from 0 to 50{
				$j: $i + 1;
				&:nth-child(#{$j }){
					transition-delay: calc(0.3*#{$i}s);
					
				}
			}
		}
	}
	&.active{
		&>.fade-in-up{
			opacity: 1;
			transform: translateY(0rem);
		}
	}
}

.slide-in-up{
	opacity: 0;
	transition: opacity .3s ease-in , transform .3s ease-in;;
	transform: translateY(2rem);
	
	&.active{
		opacity: 1;
		transform: translateY(0rem);
	}
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}