.header{
	top: 0;
	top: var(--simply-club-height);
	z-index: 9;
	width: 100%;
	position: fixed;
	#{$start-direction}: 0;
	background-color: $white;
	border-bottom: 1px solid;
	// box-shadow: 0 4px 25px 0 rgba(0,0,0,0.1);
	@include tablet(){
		border-bottom: 0px;;
	}
	&__banner{
		background-color: $primary;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		font-size: 1.6rem;
		padding: 0.7rem 0;
		@include tablet(){
			font-size: 1.2rem;
			padding: 0;
			font-weight: $semi-bold;
		}
	}
	&__banner-slide{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__content-wrap{
		@include make-row();
		align-items: center;
		padding:2rem 10.5rem 1.6rem;
		position: relative;
		@include tablet(){
			position: static;
			padding: .4rem $mobile_side_padding 1.1rem;
		}
	}
	&__hamburger-wrap{
		display: none;
		@include tablet(){
			display: flex;
			@include make-col(4.5);
		}
	}
	&__lang-switch-wrap{
		margin-#{$end-direction}: 4rem;
		padding-#{$end-direction}: 4rem;
		border-#{$end-direction}: 1px solid;
		.wpml-ls-statics-shortcode_actions{
			padding: 0;
			border: none;
		}

		@include tablet{
			display: none;
		}
	}
	&__nav-wrap{
		@include make-col(4.5);
		@include tablet(){
			@include make-col(12);
			display: none;
			position:absolute;
			top: 0;
			#{$end-direction}: 0;
			width: 100%;
			height: 100vh;;
			height: calc(var(--vh, 1vh) * 100);

			overflow-y: auto;
			overflow-x:hidden;
			background-color: transparent;
			transition: background-color 0.2s ease-in-out;
			z-index: 1;
			&.open{
				background-color: rgba(0,0,0,0.7);
			}
			&.visible{
				display: block;
				backdrop-filter: blur(3px);
				
			}
		}
		.inner-mobile-wrap{
			@include tablet(){
				width: 82%;
				height: 100%;
				background-color: $white;
				transform: translateX(100%);
			}
			&__header{
				display: none;
				@include tablet(){
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 1.4rem 2rem;
				}
			}
			&__footer{
				@include tablet(){
					text-align: center;
					padding:1rem 2.9rem 0;
					font-size: 1.4rem;
					position: fixed;
					bottom:1.5rem;
				}
			}
		}
	}
	&__social-wrap{
		@include tablet(){
			margin-top: 1.5rem;
		}
	}
	&__social-links-wrap{
		display: none;
		@include tablet{
			display: block;
			margin-top: 1.5rem;
			text-align: $end-direction;
			padding: 0 $mobile_side_padding;


		}
	}
	
	&__logo-wrap{
		@include make-col(3);
		text-align: center;
		display: flex;
	    align-items: center;
	    justify-content: center;
		.custom-logo-link{
			display: inline-block;
			.custom-logo{
				height: auto;
				display: block;
				width: $desktop-header-logo-width;
				@include tablet(){
					width: $mobile-header-logo-width
				}
			}
		}
	}
	&__actions-wrap{
		display: flex;
		justify-content: flex-end;
		align-items: center;
		@include make-col(4.5);
	}
	&__action-link{
		display: block;
		margin-#{$end-direction}: 4rem;
		@include tablet(){
			margin-#{$end-direction}: 2rem;
			display: flex;
    		align-items: center;
		}
		&:last-child(){
			margin-#{$end-direction}: 0;
		}
		&--desktop{
			@include tablet(){
				display: none;
			}
		}
		&.btn--search{
			@include tablet(){
	            margin-#{$end-direction}: 1rem;
	        }
		}
	}
	&__search-wrap{
		margin-#{$end-direction}: 4rem;
		display: flex;
		align-items: center;
		&--desktop{
			@include tablet(){
				display: none;
			}
		}
		&--mobile{
			display: none;
			@include tablet(){
				display: flex;
				margin: 0 2rem;
			}
		}
	}
}