.image-text{
    position: relative;
    &__content-wrap{
        @include make-row();
        margin: 0 auto;
    }
    &__image-wrap{
        @include make-col(5.5);
        @include tablet{
            @include make-col(12);
            margin-bottom: 6rem;
            
        }
        img{
            width: 100%;
            height: auto;
        }
    }
    &__text-wrap{
        @include make-col(4.5);
        padding-top: 6.7rem;
        padding-#{$start-direction}: 6.7rem;
        @include tablet{
            @include make-col(12);
            padding: 0;
        }
        
        h2{
            margin-bottom: 2.6rem;
            text-align: $start-direction;
            @include tablet{
                font-size: 3.5rem;
                line-height: 3.6rem;
                margin-bottom: .3rem;

            }
            
        }
        p{
            line-height: 2.5rem;
        }
    }
    .rotating-stamp{
        position: absolute;
        bottom: -9rem;
        #{$start-direction}: 82rem;
        @include tablet{
            bottom: unset;
            top: 22.5rem;
            #{$start-direction}: 18.8rem;
        }
    }
}