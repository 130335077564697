
.main-nav{
	@include tablet(){
		background-color:$main-nav-bg-color;
		color: $main-nav-item-color;
		// margin: 1.4rem 0 2.1rem;
		// height: calc(100vh - var(--mobile-nav-header-height) - var(--mobile-search-height) - var(--mobile-nav-footer-height) - 13.5rem);
		overflow: auto;
	}
	&__list{
	   display: flex;
	   align-items: center;
		@include tablet(){
			display: block;
			margin-top: 1rem;
			border-top: 1px solid;
		}
	}
	&__list-item{
		margin-#{$end-direction}: 3.2rem;
		&>span,a{
			width: 100%;
			
			display: block;
			
			
		}
		&>span{
			@include tablet{
				z-index: 2;
				position: relative;
			}
		}
		@include tablet(){
			font-size: 1.8rem;
			letter-spacing: 0;
			line-height: 4rem;
			border-bottom: 1px solid;
			margin-#{$end-direction}: 0;
			padding: 0 $mobile_side_padding;

		}
	
		&:last-child{
			margin:0;
		}
		&.hide-d{
			display: none;
			@include tablet{
				display: block;
			}
		}
		&.wpml-ls-item{
			display: none;
			@include tablet{
				display: block;
				border-bottom: 0px;
			}
		}
		&.menu-item-type-custom{
			cursor: pointer;
			@include tablet{
				position: relative;
				&::after{
					top: 0;
					content: '+';
					z-index: 0;
					position: absolute;
					#{$end-direction}: $mobile_side_padding;
				}
			}
		}
		&>.sub-menu{
			height: 0;
			overflow: hidden;
			position: absolute;
			top: calc(100% + .2rem);
			box-sizing: border-box;
			background-color: $main-nav-bg-color;
			box-shadow: 0 3.3rem 5.8rem 0 rgba(0,0,0,0.07);

			@include tablet(){
				top: unset;
				box-shadow: none;
				position: relative;
				background-color: transparent;
				text-align: $start-direction;
			}
	 	}
	}	
}

.normal-nav{
	.sub-menu{
		width: auto;
		padding:0 4.8rem;
		text-align: center;
		#{$start-direction}: 10.5rem;
		@include tablet(){
			width: 100%;
			#{$start-direction}: 0;
			padding: 0 3rem;
		}
		.main-nav__list-item{
			margin-#{$end-direction}:0;
			&.wpml-ls-item{
				display: none;
				@include tablet{
					display: block;
				}
			}
			&:first-child(){
				margin-top: 2rem;
				@include tablet(){
					margin-top: 1.5rem;
				}
			}
			&:last-child(){
				margin-bottom: 2rem;
				@include tablet(){
					margin-bottom: 1.5rem;
				}
			}
			
		}
	}
}
.mega-nav{
	&>ul{
		&>li{
			&>.sub-menu{
				width: 100%;
				display: flex;
				padding:0 8.8rem;
				#{$start-direction}: 0;
				justify-content: center;
				@include tablet(){
					width: 100%;
					padding: 0;
					display: block;
					#{$start-direction}: unset;
				}
				.main-nav__list-item{
					box-sizing: border-box;
					padding: 6.6rem 0rem 5.7rem;;
					margin-#{$end-direction}: 5.7rem;
					@include tablet{
						padding: 1rem 0;
						border-bottom: 0px;
						margin-#{$end-direction}: 0rem;
					}
					.main-nav__list-item-link{
						text-align: center;
						display: block;
						font-size: 1.8rem;
						font-weight: $bold;
						@include tablet(){
							display: inline-block;
							font-weight: $normal;
							font-size: inherit;
							text-align: $start-direction;
						}
					}
					
				}
			}
		}
	}

	
}
.footer-nav{
	line-height: 3.8rem;
    text-align: center;
    @include tablet(){
    	display: block;
    }
	&__title{
		font-weight: $bold;
		margin-bottom: 1.4rem;
		@include tablet(){
			font-size: 1.4rem;
			margin-bottom: 1rem;
			line-height: 1;
		}
	}
	
	&__list{
		@include tablet(){
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			line-height: 1;
		}
	}
	&__list-item{
		@include tablet(){
			margin-bottom: 1.5rem;
			display: flex;
			white-space: nowrap;
		}
		&::after{
			@include tablet(){
				content:'|';
				display: inline-block;
				margin:0 1rem;
			}
		}
		&:last-child(){
			&::after{
				@include tablet(){
					display: none;
				}
			}
		}
	}
}

.products-menu{
	&__content-wrap{
		@include make-row();
		width: 100%;
		margin: 0 auto;
		padding: 5.6rem 4.6rem;
		@include tablet{
			padding: 0;
		}
	}
	&__segment{
		@include make-col(5);
		@include make-col-offset(1.25);
		@include tablet{
			@include make-col(12);
			@include make-col-offset(0);
		}
		&:first-child{
			@include make-col(5.75);
			@include make-col-offset(0);
			padding-#{$start-direction}: 5.4rem;
			@include tablet{
				@include make-col(12);
				@include make-col-offset(0);
				padding-#{$start-direction}: 0rem;
			}
		}
	}
}
.main-cat-menu{
	@include make-row();
	&>li{
		@include make-col(12/5);
		font-size: 1.8rem;
		line-height: 4rem;
		font-weight: $bold;
		@include tablet{
			position: relative;
			@include make-col(12);
			a{
				display: block;
			}
			&.menu-item-has-children::after{
				top: 1.4rem;
				content: '';
				width: .6rem;
				height: .6rem;
				position: absolute;
				#{$end-direction}: 0rem;
				transition: all .2s ease-in;
				background: url(images/accordion_arrow.svg) center / contain no-repeat;
			}
			&.open{
				&::after{
					transform: rotate(180deg);
				}
			}
			.sub-menu{
				height: 0px;
				overflow: hidden;
			}
		}

		ul{
			margin: 0;
			li{
				font-weight: $normal;
				line-height: 3rem;
			}
		}
	}
}

.sale-menu{
	@include make-row();
	@include tablet{
		margin: 2.2rem -1rem 1.5rem;
	}
	li{
		@include make-col(4);
		@include tablet{
			@include make-col(5.5);
			padding: 0 1rem;
			&:last-child{
				display: none;
			}
		}
	
		&:hover{
			.btn--sale-menu{
				opacity: 1;
			}
		}
		
	}
	&__image-wrap{
		width: 18rem;
		height: 18rem;
		overflow: hidden;
		border-radius: 50%;
		position: relative;
		margin: 0 auto 1rem;
		@include full_svg;

		@include tablet{
			width: 10.9rem;
			height: 10.9rem;
			margin: 0 auto .6rem;
		}
		
		
	}
	&__text-wrap{
		text-align: center;
		padding: 0 3rem;
		@include tablet{
			padding: 0rem;
			line-height: 1.86rem;

		}
		h3{
			font-size: 1.8rem;
			letter-spacing: 0;
			line-height: 1.4;
			margin-bottom: .5rem;
			font-weight: $semi-bold;
			@include tablet{
				font-size: 1.08rem;
				line-height: 1.86rem;
			}
		}
	}
}

.footer-menu{
	li{
		font-size: 1.8rem;
		line-height: 4rem;
		font-weight: $semi-bold;
	}
}