.popup{
	top: 0;
    #{$end-direction}: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    z-index: 999999;
    position: fixed;
    overflow-y: auto;
    animation-duration: .5s;
    animation-name: fadeIn;
    // backdrop-filter: blur(10px);
    background-color: rgba(33,33,33,0.7);
    @include tablet{
        height: 100vh;
        // height: var(--viewport-height);
    }
    &--account{
        &__dialog{
            width: 56rem;
            margin: 18.5rem auto;
            background-color: $white;
            @include tablet{
                width: 32rem;
                margin: 2.6rem auto 10rem;
            }
        }
        &__header{
            padding: 3.2rem;
            @include tablet{
                padding: 2rem 2rem 2.2rem;
            }
            .btn--popup-close{
                float: $end-direction;
            }
        }
        &__body{
            padding: 0 14.9rem 6.6rem;
            @include tablet{
                padding: 0 3rem 4rem;
            }
        }
        &__subtitle{
            display: block;
            text-align: center;
            margin-bottom: 0.7rem;
            font-size: 2.4rem;
            font-weight: $bold;
            @include tablet{
                margin-bottom: 0px;
                font-size: 2rem;
            }
        }
        
        .social-login{
            text-align: center;
            margin-bottom: 3.35rem;
            @include tablet{
                margin-bottom: 2rem;
            }
            &__title{
                font-size: 1.6rem;
                line-height: 2.3rem;
                font-weight: normal;
                margin-bottom: 1.5rem;;
            }
            &__btn-wrap{
                display: flex;
                justify-content: space-between;
            }
        }
        &__sep{
            text-align: center;
            position: relative;
            margin: 0 auto 3.4rem;
            @include tablet{
                margin-bottom: 2rem;
            }
            &::after{
                content: '';
                height: 0.1rem;
                width: 100%;
                #{$end-direction}: 0;
                background-color: $primary;
                top: calc(50% - 0.05rem);
                position: absolute;
            }
            span{
                line-height: 2.3rem;;
                font-size: 1.6rem;
                padding: 0 1rem;
                display: inline-block;
                position: relative;
                z-index: 2;
                background-color: $white;
            }
        }
        &__form-wrap{
            width: 26.2rem;
            margin: 0 auto;
            
        }
        
    }
    &--shop{
        &__dialog{
            width: 68.3rem;
            margin: 4rem auto;
            position: relative;
            background-color: $white;
            @include tablet{
                width: 90%;
                margin: 2rem auto;
            }
        }
        .btn--popup-close{
            top: 2.6rem;
            width: 2.4rem;
            height: 2.4rem;
            position: absolute;
            #{$end-direction}: 2.2rem;
        }
        &__body{
            padding: 4rem 4rem 3.7rem;
            @include tablet{
                padding: 6.2rem 1.9rem 2.2rem;
            }
        }
        &__title-wrap{
            padding: 0 12rem;
            text-align: center;
            @include tablet{
                padding: 0rem .8rem;
            }
            h2{
                line-height: 1.4;
                margin-bottom: 1.6rem;
                @include tablet{
                    font-size: 2.6rem;
                    margin-bottom: 1rem;
                }
            }
            h3{
                font-size: 2.2rem;
                line-height: 3.6rem;
                font-weight: $normal;
                margin-bottom: 1.3rem;
                letter-spacing: -0.01rem;
                @include tablet{
                    font-size: 1.8rem;
                    margin-bottom: .8rem;
                }
    
            }
        }
       
        &__container{   
            @include make-row();
            margin: 0 -2rem 4rem;
            @include tablet{
                margin: 0 -1rem 3.3rem;
            }
            .point{
                @include make-col(4);
                padding: 0 2rem;
                @include tablet{
                    padding: 0 1rem;
                }

                &:nth-child(2){
                    padding: 0 1rem;
                }
                &__image-wrap{
                    height: 8rem;
                    width: 8rem;
                    margin: 0 auto 2rem;
                    @include full_svg;
                    @include tablet{
                        height: auto;
                        width: 100%;
                    }
                }
                &__text-wrap{
                    text-align: center;
                    h3{
                        margin-bottom: 1.6rem;
                    }
                }
            }
        }
        &__image-wrap{
            margin: 0 auto 4rem;
            height: 19rem;
            width: 30rem;
            @include full_svg;
            @include tablet{
                width: 100%;
                height: 15rem;
                margin-bottom: 2.5rem;;
            }
        }
        .disclaimer{
            &__text-wrap{
                color: #A1A1A1;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                letter-spacing: -0.007rem;
                @include tablet{
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    letter-spacing: -0.006rem;
                }

            }
        }
    }
    &--cart{
        &__dialog{
            width: 68rem;
            float: $end-direction;
            height: 100%;
            transform: translateX(-100%);
            background-color: $white;
            @include tablet{
                width: 100%;
            }
        }
        &__body{
            height: calc(100% - 8rem);
            @include tablet{
                height: calc(100% - 13rem);
            }
            .widget_shopping_cart_content{
                height: 100%;
            }
        }
        &__header{
            height: 8rem;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding: 2.8rem 3.2rem;
            background-color: $cart-popup-header-bg-color;
            justify-content: space-between;
            text-align: center;
            @include tablet{
                height: 6rem;
                padding:1.7rem 2rem;
            }
        }
        
        &__title{
            width: 100%;
            font-size: 3rem;
            font-weight: $semi-bold;
            letter-spacing: normal;
            line-height: 4.2rem;
            @include tablet(){
                font-size: 2.4rem;
                line-height: 3.2rem;
            }
        }
    }    
}

