.form{
	&__wrap{
		max-width: 44.5rem;
		@include tablet(){
			max-width: 100%;
		}
		h1{
			margin-bottom: 2rem;
			@include tablet(){
				margin-bottom: 1rem;
			}
		}
		p{
			line-height: 2.3rem;
			margin-bottom: 1.5rem;;
		}
	}
	&__contact-links{
		margin-top: 6rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include tablet(){
			margin-top: 2rem;
			margin-bottom: 6rem;
		}
	}
}