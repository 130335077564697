.woocommerce-mini-cart{
	height: calc(100% - 16.6rem);
	overflow: auto;
	position: relative;
	@include tablet(){
		height: calc(100% - 13.6rem);
	}

	&__empty-message{
		padding:2.6rem 3.2rem;
		font-size: 2.2rem;
		@include tablet(){
			padding: 2.6rem $mobile_side_padding;
		}
	}
	.free-shipping-notice-wrap{	
		position: absolute;
		bottom: 1.7rem;
		width: 100%;;
		&__content-wrap{
			background-color: #F7F3F0;
			height: 5.6rem;
			font-size: 1.9rem;
			padding: 1.6rem 2.5rem;
			margin: 0 auto;
			width: 55%;
			box-sizing: border-box;
			@include tablet{
				width: 85%;
				font-size: 1.3rem;
			}
		}
		
		
		p{
			.amount{
				font-weight: bold;
			}
			&::before{
				content: '';
				width: 2.9rem;
				height: 2.9rem;
				margin-#{$end-direction}: 1.2rem;
				display: inline-block;
				vertical-align: middle;
				background: url(images/truck.svg) center / contain no-repeat;
			}
		}
	}
	.woocommerce-mini-cart-item{
		@include make-row();
		position: relative;
		box-sizing: border-box;
		padding:2.6rem 3.2rem;
		border-bottom: 1px solid #DBDBDB;
		@include tablet(){
			padding:1.7rem 1.4rem;
		}
		&__product-link{
			display: flex;
			@include make-col(377,616);
			@include make-col-offset(55,616);
			@include tablet{
				@include make-col(9);
				@include make-col-offset(0);
			}
			img{
				width: 11rem;
				height: 11rem;
				object-fit: cover;
				margin-#{$end-direction}: 3.2rem;
				@include tablet(){
					height: 10rem;
					width: 10rem;
					margin-#{$end-direction}: 1.3rem;
				}
			}
			span{
				display: block;
				font-weight: $bold;
				max-width: 23.5rem;
				padding-top: 2.7rem;
				@include tablet(){
					padding-top: 0;
					line-height: 1;
					font-weight: $normal;
					font-size: 1.6rem;
					max-width: 100%;
				}
				
			}
		}
		.quantity{
			@include make-col(60,616);
			@include make-col-offset(38,616);
			padding-top: 2.7rem;
			font-weight: $semi-bold;
			@include tablet(){
				order: 3;
				padding-top: 0;
				margin-top: -1.5rem;
				@include make-col(6);
				@include make-col-offset(0);
				padding-#{$start-direction}: 12rem;
				.number-input-wrap{
					font-weight: $normal;
					font-size: 2.5rem;
					line-height: 1;
				}
			}
		}
		.price-wrap{
			@include make-col(86,616);
			padding-top: 2.7rem;
			text-align: $end-direction;
			@include tablet(){
				padding-top: 0;
				@include make-col(3);
				order: 2;
			}
			.amount{
				display: block;
				font-weight: $bold;
				
			}
			.remove{
				position: absolute;
				#{$end-direction}: 3.25rem;
				bottom: 4.6rem;
				color: $gray;
				font-size: 1.6rem;
				@include tablet(){
					font-size: 1.4rem;
					#{$end-direction}: 2rem;
					bottom: 1.9rem;
					box-sizing: 3rem;
				}
			}
		}
	}
	&__total{
		padding: 2.6rem 4rem 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 2.4rem;	
		border-top:1px solid #DBDBDB;
		@include tablet(){
			padding:1.6rem $mobile_side_padding 0;
			font-size: 1.6rem;
			color: $bronze;
			border-top:1px solid $primary;
		}
		strong{
			font-weight: $normal;
		}
		.amount{
			font-weight: $bold;
			@include tablet(){
				font-size: 2rem;
			}
		}
	}
	&__buttons{
		margin-top: 3.2rem;
		padding: 0 3.2rem;
		@include tablet(){
			padding: 0 $mobile_side_padding;
			margin-top: 1.8rem;
		}
		.wc-forward{
			display: none;
			&.checkout{
				display: block;
				@extend .btn;
				@extend .btn--primary;
                @extend .btn--primary-reverse;
				text-align: center;
				line-height: 2.6rem;
				font-size: 2rem;
				font-weight: $semi-bold;
				letter-spacing: 0.2rem;
				padding: 1.5rem 0;
			}
		}
	}
}