.slider{
	.slick-slide {
		@include tablet(){
	   		margin: 0 0rem;
	   	}
	  }
	.slick-arrow{
		top: calc(50% - 2.7rem);
		width: 5.4rem;
		height: 5.4rem;
		position: absolute;
		z-index: 1;
		font-size: 0px;
		border: 0px;
		cursor: pointer;
		-webkit-appearance: none;
		background-color: transparent;
		background: url(images/slick-arrow.svg) center / contain no-repeat;
		@include tablet{
			width: 2.5rem;
			height: 2.5rem;
			background-image: url(images/slick-arrow-mobile.svg);
		}
	    
		&:focus{
			outline: none;
		}
	}
	.slick-prev{
		#{$start-direction}: 3.1rem;
		
	}
	.slick-next{
		#{$end-direction}: 3.1rem;
		transform: scale(-1,1);
	}
		
	
	.slick-dots{
		position: absolute;
   		bottom: 1.8rem;
   		#{$start-direction}: $mobile_side_padding;
   		display: flex;
   		align-items: center;
		@include tablet{
			width: 100%;
			box-sizing: border-box;
			justify-content: center;
			#{$start-direction}: 0;
			padding: 0 $mobile_side_padding;
		}
   		li{
			width: 1.8rem;
			cursor: pointer;
   			height: 1.8rem;
   			border-radius: 50%;
			position: relative;
   			border:1px solid transparent;
   			margin-#{$end-direction}: 0.8rem;
			&::after{
				content: '';
				width: .9rem;
				height: .9rem;
				position: absolute;
				border-radius: 50%;
				top: calc(50% - .45rem);
				background-color: $white;
				#{$start-direction}: calc(50% - .45rem);
				
			}
   			&.slick-active{
				border:1px solid $white;
   			}
   			button{
   				display: none;
   			}
   		}
	}
	&--home-cta{
		.slick-arrow{
			@include tablet{
				top: 14rem;
			}
		}
		.slick-next{
			#{$end-direction}: calc(50% + 3.1rem);
			@include tablet{
				#{$end-direction}: 1.6rem;
			}
		}
		.slick-prev{
			@include tablet{
				#{$start-direction}: 1.6rem;
			}
		}
		.slick-dots{
			@include tablet{
				bottom: 27.1rem;
			}
		}
	}
	&--black-full-arrows{
		.slick-arrow{
			top: 10.1rem;
			width: 5.4rem;
			height: 5.4rem;
			background: url(images/slick-full-arrow-black.svg) center / contain no-repeat;

		}
		.slick-prev{
			#{$start-direction}: -12rem;
			
		}
		.slick-next{
			#{$end-direction}: calc(-12rem - 5.4rem);
		}
	}
	&--additional-scents{

	}
	&--post-products{
		.slick-arrow{
			top: 19.2rem;
		}
	}
	&--black-arrows{
		.slick-arrow{
			top: 17.1rem;
			width: 3.6rem;
			height: 3.6rem;
			background: url(images/slick-arrow-black.svg) center / contain no-repeat;
		}
		.slick-prev{
			#{$start-direction}: -6.45rem;
			
		}
		.slick-next{
			#{$end-direction}: calc(-6.45rem - 3.6rem);
		}
	}
	&--products{
		
	}
	&--product-main{

		.slick-slide{
			img{
				height: 76rem;
				@include tablet(){
					height: 46rem;
				}
			}
		}
	}
	&--product-nav{
		text-align: center;
		.slick-list{

		}
		&__slide{
			&:hover{
				cursor:pointer;
			}
		}
		.slick-arrow{
			position: relative;
			&.slick-next{
				background-image: url(images/slick_down.svg);
				width: 2.9rem;
				height: 1.3rem;
				margin: 8.5rem auto 0; 
			}
			&.slick-prev{
				display: none !important;
			}
		}
	}
}
.slick-lightbox{
	position: relative;
	.slick-list{
		@include tablet{
			padding: 0 !important;
		}
	}
	.slick-arrow{
		top: calc(50% - 1.65rem);
		width: 3.8rem;
		height: 3.3rem;
		position: absolute;
		z-index: 1;
		font-size: 0px;
		border: 0px;
		cursor: pointer;
		-webkit-appearance: none;
		background-color: transparent;
		@include tablet(){
			width: 1rem;
			height: 2rem;
			top: calc(50% - 1rem);
		}
		&:focus{
			outline: none;
		}
		&:hover{
			background-color:unset;
		}
	}
	.slick-prev{
		background: url(images/slick-next-white.svg) center / contain no-repeat;
		z-index: 2;
		#{$end-direction}: 4% !important;
		@include tablet(){
			#{$end-direction}: 2% !important;
		}
		&:before{
			display: none;
		}
	}
	.slick-next{
		background: url(images/slick-prev-white.svg) center / contain no-repeat;
		#{$start-direction}: 4% !important;
		@include tablet(){
			#{$start-direction}: 2% !important;
		}
		&:before{
			display: none;
		}
	}
	.slick-lightbox-slick-img{
		max-width: 90%;
		@include tablet(){
			width: 90%;
		}
	}
	.slick-lightbox-close{
		height: 4rem;
		width: 4rem;
		top: 4rem;
		#{$end-direction}: 4rem;
		#{$start-direction}: unset;
		@include tablet(){
			top: 1rem;
			#{$start-direction}: 1rem;
		}
		&:before{
			font-size: 5rem;
			opacity: 1;
			font-family: $font;
			font-weight: $light;
			@include tablet(){
				font-size: 5rem;
			}
		}
	}
	.custom_paging{
		color: $white;
	    position: absolute;
	    bottom: 2vw;
	    width: 100%;
	    text-align: center;
	    li{
	    	display: none;
	    	letter-spacing:3.6px;
	    	&.slick-active{
	    		display: block;
	    	}
	    }
	}
}